import React, { useEffect, useState } from 'react';
import TextInput from '../../components/common/TextInput/TextInput';
import logo from '../../assets/vcclogo.png';
import ButtonCustom from '../../components/common/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreatorsAuth } from '../../state';
import { RootState } from 'state/store';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import CodeVerifier from 'components/common/CodeVerifier/CodeVerifier';
import IndeterminateProgressBar from 'components/common/IndeterminateProgressBar/IndeterminateProgressBar';

const Login = () => {
  const dispatch = useDispatch();
  const { userLogin, verifySecurityCode, setLoginData } = bindActionCreators(
    actionCreatorsAuth,
    dispatch,
  );
  const state = useSelector((state: RootState) => state.auth);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'normal',
        callback: async () => {
          setRecaptcha(recaptchaVerifier);
        },
      },
      getAuth(),
    );

    recaptchaVerifier.render();
    return () => {
      recaptchaVerifier.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = formData.email.trim();
    const password = formData.password.trim();
    if (email.trim().length === 0) {
      dispatch(
        setLoginData({
          property: 'errorMessage',
          value: 'Email cannot be empty',
        }),
      );
    } else if (password.trim().length === 0) {
      dispatch(
        setLoginData({
          property: 'errorMessage',
          value: 'Password cannot be empty',
        }),
      );
    } else {
      await userLogin(email, password, recaptcha);
    }
  };

  const handleConfirmSecurityCode = async () => {
    const verificationCode = state.loginInfo.verificationCode.trim();
    if (verificationCode.trim().length === 0 && verificationCode.length < 6) {
      dispatch(
        setLoginData({
          property: 'errorMessage',
          value: 'Security Code cannot be empty or less than 6 digits',
        }),
      );
    } else {
      await verifySecurityCode();
    }
  };

  const handleChangeText = (
    property: string,
    { currentTarget }: React.FormEvent<HTMLInputElement>,
  ) => {
    setFormData(prevState => ({
      ...prevState,
      [property]: currentTarget.value,
    }));
  };

  const handleChangeVerificationCode = (value: string) => {
    dispatch(setLoginData({ property: 'verificationCode', value: value }));
  };

  return (
    <>
      <IndeterminateProgressBar show={state.isLoadingLogin} />

      <div className="flex flex-col justify-center items-center h-screen  bg-gray-100">
        {state.loginInfo.MFA && (
          <div className="flex w-full max-w-sm text-gray-600 text-sm">
            <a href="/login" className="flex items-center gap-1 cursor-pointer">
              <ArrowLeftIcon className="w-4 h-4" /> <p>Return to login</p>
            </a>
          </div>
        )}
        <div className="bg-white shadow-lg rounded-lg p-10 w-full max-w-sm">
          <div className="flex justify-center">
            <img src={logo} className="h-24" alt="logo" />
          </div>
          <p className="text-blue-500 font-semibold text-lg text-center">
            VCC Host Portal Login
          </p>
          {state.loginInfo.errorMessage && (
            <div
              className="flex items-center gap-1 justify-center bg-red-100 rounded-lg py-2 px-2 text-sm text-center  text-red-700 mb-3"
              role="alert">
              <div className="mt-1">{state.loginInfo.errorMessage}</div>
            </div>
          )}
          <form onSubmit={handleLogin}>
            <div className={`${state.loginInfo.MFA ? 'hidden' : 'block'}`}>
              <div className="my-2">
                <TextInput
                  value={formData.email}
                  onChange={e => handleChangeText('email', e)}
                  size="small"
                  label="Email"
                  type="email"
                  required
                  placeholder="Enter your email"
                />
              </div>
              <div className={'my-2'}>
                <TextInput
                  value={formData.password}
                  onChange={e => handleChangeText('password', e)}
                  size="small"
                  label="Password"
                  type="password"
                  required
                  placeholder="Enter your password"
                />
              </div>
              <div
                id="recaptcha-container"
                className={`${
                  state.isLoadingLogin ? 'select-none' : ''
                }`}></div>
              <div className="flex flex-col justify-center mt-6">
                <ButtonCustom
                  loading={state.isLoadingLogin}
                  loadingText="Logging in..."
                  disabled={
                    formData.password.trim().length === 0 ||
                    formData.email.trim().length === 0 ||
                    state.isLoadingLogin
                  }
                  type="submit"
                  size="small"
                  status="primary"
                  title="Login"
                />
                <div className="flex justify-center">
                  <a
                    href="/auth/forgot-password"
                    className="mt-2 text-center text-sm text-gray-600 w-max">
                    Forgot password?
                  </a>
                </div>
              </div>
            </div>
            <div className={`${state.loginInfo.MFA ? 'block' : 'hidden'}`}>
              {state.loginInfo.MFA && (
                <div className="text-sm text-justify mt-2 mb-3 text-gray-500">
                  We sent you a 6 digit code to{' '}
                  {
                    JSON.parse(
                      JSON.stringify(state.loginInfo.resolver?.hints[0]),
                    ).phoneNumber
                  }
                  . Please enter the code to log in.
                </div>
              )}
              <div className="text-sm">Enter 6 digit code</div>
              <CodeVerifier
                length={6}
                isLoading={state.isLoadingLogin}
                getCode={handleChangeVerificationCode}
                submitCode={handleConfirmSecurityCode}
              />
              <div className="flex flex-col justify-center mt-6">
                <ButtonCustom
                  loading={state.isLoadingLogin}
                  loadingText="Validating..."
                  disabled={
                    state.loginInfo.verificationCode.trim().length < 6 ||
                    state.isLoadingLogin
                  }
                  size="small"
                  status="primary"
                  title="Confirm and login"
                  onClick={handleConfirmSecurityCode}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
