import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SideNav.module.scss';

interface SideNavItem extends React.HtmlHTMLAttributes<HTMLDivElement> {
  title: string;
  selected?: boolean;
  icon: JSX.Element;
  to?: string;
  external?: boolean;
  noredirect?: boolean;
}

const SideNavItem = ({
  title,
  selected,
  icon,
  to,
  external,
  noredirect,
  ...others
}: SideNavItem) => {
  return (
    <div className="relative" {...others} title={title}>
      {external ? (
        <a
          href={noredirect ? '' : to ? to : ''}
          target="_blank"
          rel="noreferrer"
          className={`${styles.sideItem} ${
            selected ? styles.sideItemSelected : ''
          }`}
          data-mdb-ripple="true"
          data-mdb-ripple-color="primary">
          {icon}
          <span>{title}</span>
        </a>
      ) : (
        <Link
          to={noredirect ? '' : to ? to : ''}
          className={`${styles.sideItem} ${
            selected ? styles.sideItemSelected : ''
          }`}>
          {icon}
          <span>{title}</span>
        </Link>
      )}
    </div>
  );
};

export default SideNavItem;
