export const SET_USER_DATA = 'SET_DATA';
export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const TOGGLE_LOADING_LOGIN = 'TOGGLE_LOADING_LOGIN';
export const TOGGLE_LOADING_RESET_PASS = 'TOGGLE_LOADING_RESET_PASS';
export const TOGGLE_LOADING_LOGOUT = 'TOGGLE_LOADING_LOGOUT';

// Dashboard actions
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_OBJECTIVES_DATA = 'SET_OBJECTIVES_DATA';
export const SET_EVENTS_DATA = 'SET_EVENTS_DATA';
export const TOGGLE_LOADING_DASHBOARD = 'TOGGLE_LOADING_DASHBOARD';
export const TOGGLE_LOADING_EVENTS = 'TOGGLE_LOADING_EVENTS';
export const TOGGLE_LOADING_OBJECTIVES = 'TOGGLE_LOADING_OBJECTIVES';

// Player objectives actions
export const SET_PLAYERS_DATA = 'SET_PLAYERS_DATA';
export const RESET_PLAYERS_DATA = 'RESET_PLAYERS_DATA';
export const TOGGLE_LOADING_MORE_PLAYERS = 'TOGGLE_LOADING_MORE_PLAYERS';
export const SET_PLAYER_DATA = 'SET_PLAYER_DATA';
export const TOGGLE_LOADING_PLAYERS = 'TOGGLE_LOADING_PLAYERS';
export const TOGGLE_LOADING_PLAYER_DATA = 'TOGGLE_LOADING_PLAYER_DATA';
export const IS_UPDATING_PLAYERS_DATA = 'IS_UPDATING_PLAYERS_DATA';
export const IS_UPDATING_PLAYER_DATA = 'IS_UPDATING_PLAYER_DATA';
export const TOGGLE_UPDATING_NOTE = 'TOGGLE_UPDATING_NOTE';
export const TOGGLE_CREATING_NOTE = 'TOGGLE_CREATING_NOTE';
export const TOGGLE_IS_DELETING_NOTE = 'TOGGLE_IS_DELETING_NOTE';
export const TOGGLE_UPDATING_PLAYER_OBJECTIVE_STATUS =
  'TOGGLE_UPDATING_PLAYER_STATUS';
export const IS_UPDATING_MODAL_PLAYER_OBJECTIVE_STATUS =
  'IS_UPDATING_MODAL_PLAYER_OBJECTIVE_STATUS';

// Search actions
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const TOGGLE_SEARCHING_PLAYER = 'TOGGLE_SEARCHING_PLAYER';
export const TOGGLE_LOADING_MORE = 'TOGGLE_LOADING_MORE';
