import React from 'react';
import style from './IndeterminateProgressBar.module.scss';

interface IndeterminateProgressBarTypes {
  show?: boolean;
}

const IndeterminateProgressBar = ({ show }: IndeterminateProgressBarTypes) => {
  return (
    <div className={`${style.proggressBar} ${show ? 'block' : 'hidden'}`}>
      <div className={style.progressBarValue}></div>
    </div>
  );
};

export default IndeterminateProgressBar;
