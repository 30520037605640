import {
  AnnotationIcon,
  PlusSmIcon,
  SaveIcon,
  TrashIcon,
  UserCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { handleFormatDateTime } from 'helpers/common';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreatorsPlayers } from 'state';
import { NoteRecord } from 'state/interfaces';
import { RootState } from 'state/store';
import ButtonCustom from '../../components/common/Button/Button';
import List from '../../components/common/List/List';
import ListItem from '../../components/common/List/ListItem';
import Modal from '../../components/common/Modal/Modal';
import TextArea from '../../components/common/TextInput/TextArea';
import TextInput from '../../components/common/TextInput/TextInput';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './fadeAnimation.css';

interface PlayerNotes {
  notes: undefined | NoteRecord[] | null;
}
const INITIAL_DATA: NoteRecord = {
  id: '',
  Created_Time: '',
  Modified_Time: '',
  Note_Content: '',
  Note_Title: '',
  Created_By: { name: '' },
};

const PlayerNotes = ({ notes }: PlayerNotes) => {
  const [modalNotesVisible, setModalNotesVisible] = useState(false);
  const [modalEditNotesVisible, setModalEditNotesVisible] = useState(false);
  const [formData, setFormData] = useState<NoteRecord>(INITIAL_DATA);
  const playersState = useSelector((state: RootState) => state.players);
  const dispatch = useDispatch();
  const { createNote, updateNote, deleteNote } = bindActionCreators(
    actionCreatorsPlayers,
    dispatch,
  );

  const notesRef = useRef<HTMLDivElement>(null);

  const handleToggleNotesModal = () => {
    setFormData(INITIAL_DATA);
    setModalNotesVisible(prevState => !prevState);
  };

  const handleToggleEditNotesModal = () => {
    setModalEditNotesVisible(prevState => !prevState);
  };

  const handleEditNote = (note: NoteRecord) => {
    setFormData(note);
    if (!note.Note_Title) note.Note_Title = '';
    if (!note.Note_Content) note.Note_Content = '';

    setModalEditNotesVisible(prevState => !prevState);
  };

  const handleChangeTitle = (e: React.FormEvent<HTMLInputElement>) => {
    setFormData({ ...formData, Note_Title: e.currentTarget.value });
  };
  const handleChangeDesciption = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, Note_Content: e.currentTarget.value });
  };

  const handleSaveChanges = async () => {
    if (!formData.Note_Title || formData.Note_Title.trim() === '') {
      alert('You need to provide a title');
    } else if (formData.Note_Content.trim() === '') {
      alert('You need to provide a description');
    } else {
      await updateNote(formData.id, formData.Note_Title, formData.Note_Content);
      handleToggleEditNotesModal();
    }
  };

  const handleDeleteNote = async () => {
    if (confirm('Are you sure you want to Delete this note?')) {
      await deleteNote(formData.id);
      handleToggleEditNotesModal();
    }
  };

  const handleCreateNote = async () => {
    if (!formData.Note_Title || formData.Note_Title.trim() === '') {
      alert('You need to provide a title');
    } else if (formData.Note_Content.trim() === '') {
      alert('You need to provide a description');
    } else {
      await createNote(formData.Note_Title, formData.Note_Content);
      setModalNotesVisible(prevState => !prevState);
      if (notesRef.current)
        notesRef.current.scrollTo({
          behavior: 'smooth',
          top: 0,
        });
    }
  };

  return (
    <>
      {/* List of notes */}
      <div className="bg-gray-50 rounded-md h-full w-full lg:w-64 p-3">
        <List>
          <ListItem unique cols={'grid-cols-1'}>
            <div className="flex justify-between items-center w-full border-b pb-2">
              <div className="flex flex-col">
                <p className="text-md font-semibold text-gray-800">Notes</p>
              </div>
              <div>
                <ButtonCustom
                  ripple
                  size="xsmall"
                  title="Add"
                  iconRight={<PlusSmIcon className="w-4 h-4 stroke-[3]" />}
                  status={'primary'}
                  onClick={handleToggleNotesModal}
                />
              </div>
            </div>
          </ListItem>
          {notes && notes.length > 0 ? (
            <div ref={notesRef} className="overflow-y-auto max-h-[850px]">
              <TransitionGroup>
                {notes.map((note, key) => (
                  <CSSTransition
                    key={`note_${key}`}
                    in={true}
                    timeout={400}
                    classNames="fade"
                    appear>
                    <ListItem
                      pointer
                      unique={notes.length === key + 1}
                      onClick={() => handleEditNote(note)}>
                      <div>
                        <div className="flex items-center gap-1 mb-2">
                          <div>
                            <UserCircleIcon className="w-7 h-7 text-gray-800 stroke-[1]" />
                          </div>
                          <div className="flex flex-col text-xs ">
                            <span>{note.Created_By.name}</span>
                            <span className="text-gray-500">
                              {handleFormatDateTime(note.Created_Time)}
                            </span>
                          </div>
                        </div>
                        <p className="text-sm text-blue-500">
                          {note.Note_Title ? note.Note_Title : '(No title)'}
                        </p>
                        <p className="text-sm text-black text-justify">
                          {note.Note_Content}
                        </p>
                      </div>
                    </ListItem>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center gap-1 text-gray-500 select-none">
              <AnnotationIcon className="w-8 h-8 stroke-[1]" />
              <p className="text-sm text-center">
                No player notes. Add a new note
              </p>
            </div>
          )}
        </List>
      </div>
      {/* Modal to create a note */}
      <Modal
        level={2}
        opened={modalNotesVisible}
        toggleModal={handleToggleNotesModal}
        id="ModalNotes"
        size="modal-md"
        header="Add note to player"
        content={
          <div className="px-2">
            <div className="mb-3">
              <TextInput
                value={formData.Note_Title}
                type="text"
                size="small"
                label="Title"
                placeholder="Enter note title"
                onChange={handleChangeTitle}
              />
            </div>
            <TextArea
              value={formData.Note_Content}
              size="small"
              label="Description"
              placeholder="Enter note description"
              onChange={handleChangeDesciption}
            />
          </div>
        }
        footer={
          <>
            <ButtonCustom
              rounded
              ripple
              size="small"
              outlined
              title="Close"
              status="primary"
              onClick={handleToggleNotesModal}
              iconLeft={<XIcon className="w-4 h-4" />}
            />
            <ButtonCustom
              rounded
              size="small"
              title="Create"
              status="primary"
              loading={playersState.isCreatingNote}
              disabled={
                formData.Note_Title === '' ||
                formData.Note_Content === '' ||
                playersState.isCreatingNote
              }
              iconLeft={<PlusSmIcon className="w-5 h-5 stroke-[2]" />}
              onClick={handleCreateNote}
            />
          </>
        }
      />
      {/* Modal to edit a note */}
      <Modal
        level={2}
        opened={modalEditNotesVisible}
        toggleModal={handleToggleEditNotesModal}
        id="ModalEditNote"
        size="modal-md"
        header="Edit note"
        content={
          <div className="px-2">
            <div className="mb-3">
              <TextInput
                value={formData.Note_Title}
                type="text"
                size="small"
                label="Title"
                placeholder="Enter note title"
                onChange={handleChangeTitle}
              />
            </div>
            <TextArea
              value={formData.Note_Content}
              size="small"
              label="Description"
              placeholder="Enter note description"
              onChange={handleChangeDesciption}
            />
          </div>
        }
        footer={
          <div className="w-full flex gap-2 justify-between">
            <ButtonCustom
              rounded
              iconLeft={<TrashIcon className="w-4 h-4" />}
              size="small"
              outlined
              title="Delete"
              status="danger"
              disabled={
                playersState.isDeletingNote || playersState.isUpdatingNote
              }
              loading={playersState.isDeletingNote}
              loadingText={'Deleting...'}
              onClick={handleDeleteNote}
            />
            <div className="flex gap-2">
              <ButtonCustom
                rounded
                ripple
                iconLeft={<XIcon className="w-4 h-4" />}
                size="small"
                outlined
                title="Close"
                status="primary"
                onClick={handleToggleEditNotesModal}
              />
              <ButtonCustom
                rounded
                iconLeft={<SaveIcon className="w-4 h-4" />}
                size="small"
                title="Save"
                status="success"
                disabled={
                  formData.Note_Title === '' ||
                  formData.Note_Content === '' ||
                  playersState.isUpdatingNote ||
                  playersState.isDeletingNote
                }
                loading={playersState.isUpdatingNote}
                loadingText={'Saving...'}
                onClick={handleSaveChanges}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default PlayerNotes;
