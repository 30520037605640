import { ArrowRightIcon } from '@heroicons/react/outline';
import React from 'react';
import logo from '../../assets/vcclogo.png';
import ButtonCustom from 'components/common/Button/Button';

const Nomatch404 = () => {
  const handleGoDashboard = () => {
    window.location.href = '/';
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-screen text-center p-4 bg-white">
      <p className="txt-main text-7xl text-blue-500 ">404</p>
      <img src={logo} className="w-28 h-28 object-contain" alt="logo" />
      <p className="text-gray-500 text-sm md:text-lg mb-2">
        The page you are looking is not available
      </p>
      <ButtonCustom
        iconRight={<ArrowRightIcon className="h-5 w-5 mr-2" />}
        rounded
        size="small"
        title="Go to dashboard"
        status="primary"
        onClick={handleGoDashboard}
      />
    </div>
  );
};

export default Nomatch404;
