import React from 'react';

interface List {
  children: React.ReactNode;
}
const List = ({ children }: List) => {
  return <div>{children}</div>;
};

export default List;
