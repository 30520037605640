import React from 'react';
import Cruiser from 'assets/vcc_cruiser.png';
import styles from './Slider.module.scss';

const Slider = () => {
  return (
    <div
      id="carouselIndicators"
      className="carousel slide relative"
      data-bs-ride="carousel">
      <div
        className={`carousel-indicators ${styles.sliderIndicatorsContainer}`}>
        <button
          type="button"
          data-bs-target="#carouselIndicators"
          data-bs-slide-to="0"
          className={`active ${styles.sliderIndicator}`}
          aria-current="true"
          aria-label="Slide 1"></button>
      </div>
      <div className="carousel-inner relative w-full overflow-hidden">
        <div className="carousel-item active float-left w-full">
          <img src={Cruiser} className="block w-full" alt="Cruise" />
        </div>
      </div>
      {/* <button
        className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
        type="button"
        data-bs-target="#carouselIndicators"
        data-bs-slide="prev">
        <span
          className="carousel-control-prev-icon inline-block bg-no-repeat"
          aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
        type="button"
        data-bs-target="#carouselIndicators"
        data-bs-slide="next">
        <span
          className="carousel-control-next-icon inline-block bg-no-repeat"
          aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button> */}
    </div>
  );
};

export default Slider;
