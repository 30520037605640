import React from 'react';

type SkeletonProps = {
  items: number;
};
const EventSkeleton = (props: SkeletonProps) => {
  const { items } = props;
  const renderItem = (item: number, key: number) => {
    return (
      <div
        key={`sk_${key}`}
        className={`bg-secondary py-3 w-full mx-auto ${
          items === key + 1 ? '' : 'border-b'
        }  border-gray-200 border-dashed`}>
        <div className="animate-pulse flex space-x-4">
          <div className=" bg-slate-100 h-18 w-[70px]"></div>
          <div className="flex-1 space-y-4">
            <div className="h-5 bg-slate-100"></div>
            <div className="">
              <div className="h-8 w-28 bg-slate-100"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="grid gap-2 grid-cols-1">
      {[...Array(items)].map(renderItem)}
    </div>
  );
};

export default EventSkeleton;
