import React, { useEffect, useState } from 'react';
import DropDownItem from './DropDownItem';
import styles from './DropDown.module.scss';
import ButtonCustom from '../Button/Button';
import { SizesTypes, StatusTypes } from '../../../interfaces';

interface DropdownContainer {
  status: StatusTypes;
  selected: string;
  options: string[];
  outlined?: boolean;
  rounded?: boolean;
  size?: SizesTypes;
  shadow?: boolean;
  full?: boolean;
  loading?: boolean;
  loadingText?: string;
  setStatusOnChangeSelected?: (value: string) => StatusTypes;
  updateSelected?: string;
  updateSelectedStatus?: boolean;
  onChangeSelected?: (Objective_status: string) => void;
}

const DropdownContainer = ({
  status,
  selected,
  outlined,
  rounded,
  options,
  size,
  shadow,
  full,
  loading,
  loadingText,
  setStatusOnChangeSelected,
  updateSelected,
  updateSelectedStatus,
  onChangeSelected,
}: DropdownContainer) => {
  const [currentSelected, setCurrentSelected] = useState({
    title: selected,
    status: status,
  });

  const handleOnCurrentSelected = async (title: string) => {
    if (setStatusOnChangeSelected) {
      const newStatus = await setStatusOnChangeSelected(title);
      setCurrentSelected({ title, status: newStatus });
    } else {
      setCurrentSelected({ title, status });
    }
    if (onChangeSelected) {
      await onChangeSelected(title);
    }
  };

  useEffect(() => {
    if (updateSelected) {
      if (updateSelectedStatus) {
        if (setStatusOnChangeSelected) {
          const newStatus = setStatusOnChangeSelected(updateSelected);
          setCurrentSelected({ title: updateSelected, status: newStatus });
        } else {
          setCurrentSelected(prevState => ({
            ...prevState,
            title: updateSelected,
          }));
        }
      } else
        setCurrentSelected(prevState => ({
          ...prevState,
          title: updateSelected,
        }));
    }
  }, [updateSelected, updateSelectedStatus, setStatusOnChangeSelected]);
  return (
    <div className="dropdown relative">
      <ButtonCustom
        size={size}
        className={'dropdown-toggle'}
        status={currentSelected.status}
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        title={currentSelected.title}
        rounded={rounded}
        outlined={outlined}
        shadow={shadow}
        full={full}
        isDropDown
        loading={loading}
        loadingText={loadingText ? loadingText : ''}>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="caret-down"
          className="w-2 ml-2"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512">
          <path
            fill="currentColor"
            d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path>
        </svg>
      </ButtonCustom>
      <ul
        className={`dropdown-menu ${styles.dropdownCustom} ${
          loading ? '!hide' : ''
        }`}
        aria-labelledby="dropdownMenuButton1">
        {options.map(title => (
          <DropDownItem
            key={`opt-${title}`}
            title={title}
            selected={currentSelected.title}
            onChangeSelected={handleOnCurrentSelected}
          />
        ))}
      </ul>
    </div>
  );
};

export default DropdownContainer;
