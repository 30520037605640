import { Status } from 'constants/general';
import styles from '../Button.module.scss';

export const BUTTON_TYPES = {
  [Status.PRIMARY]: {
    filled: styles.btnFilledPrimary,
    outlined: styles.btnPrimary,
  },
  [Status.SUCCESS]: {
    filled: styles.btnFilledSuccess,
    outlined: styles.btnSuccess,
  },
  [Status.DEFAULT]: {
    filled: styles.btnFilledDefault,
    outlined: styles.btnDefault,
  },
  [Status.WARNING]: {
    filled: styles.btnFilledWarning,
    outlined: styles.btnWarning,
  },
  [Status.DANGER]: {
    filled: styles.btnFilledDanger,
    outlined: styles.btnDanger,
  },
  [Status.MAGIC]: {
    filled: styles.btnFilledMagic,
    outlined: styles.btnMagic,
  },
  [Status.LOVE]: {
    filled: styles.btnFilledLove,
    outlined: styles.btnLove,
  },
  [Status.POWERFUL]: {
    filled: styles.btnFilledPowerful,
    outlined: styles.btnPowerful,
  },
  [Status.RELAX]: {
    filled: styles.btnFilledRelax,
    outlined: styles.btnRelax,
  },
  [Status.DARK]: {
    filled: styles.btnFilledDark,
    outlined: styles.btnDark,
  },
  [Status.AMBER]: {
    filled: styles.btnFilledAmber,
    outlined: styles.btnAmber,
  },
};
