import React, { useState } from 'react';
import {
  CheckIcon,
  ClipboardListIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/outline';
import ButtonCustom from 'components/common/Button/Button';
import DropdownContainer from 'components/common/DropDown/DropDownContainer';
import List from 'components/common/List/List';
import ListItem from 'components/common/List/ListItem';
import Modal from 'components/common/Modal/Modal';
import Tabs from 'components/common/Tabs/Tabs';
import Notes from './PlayerNotes';
import { handleGetStatusType, OBJECTIVE_STATUS } from '../../constants/general';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import PlayerDetailsSkeleton from './skeleton/PlayerDetailsSkeleton';
import {
  formatPhoneNumber,
  handleFormatDate,
  handleFormatDateTime,
  handleFormatMoney,
} from 'helpers/common';
import { bindActionCreators } from 'redux';
import { actionCreatorsPlayers } from 'state';
import TextInput from 'components/common/TextInput/TextInput';
import { PlayerData, ReservationRecord } from 'state/interfaces';
import { calcUpDown } from 'helpers/calcUpDown';
import OutsideAlerter from 'hooks/useClickOutside';

interface PlayerDetailsModal {
  isModalOpened: boolean;
  handleTogglePlayerDetailsModal: (id_row?: number) => void;
  data: PlayerData;
  isObjectivesView?: boolean;
}

const PlayerDetailsModal = ({
  isModalOpened,
  handleTogglePlayerDetailsModal,
  data,
  isObjectivesView,
}: PlayerDetailsModal) => {
  const playersState = useSelector((state: RootState) => state.players);
  const playerState = useSelector(
    (state: RootState) => state.players,
  ).playerData;

  const [toggleEditAssociatedPlayer, setToggleEditAssociatedPlayer] = useState({
    show: false,
    value: '',
  });

  const dispatch = useDispatch();
  const {
    updatePlayerPreferredContact,
    updatePlayerObjectiveStatus,
    updatePlayerObjectiveStatusModal,
    updatePlayerSnowBirdType,
    updateAssociatedPlayer,
  } = bindActionCreators(actionCreatorsPlayers, dispatch);

  const handleUpdatePlayerObjectiveStatus = async (
    Objective_Status: string,
    id?: string,
  ) => {
    await updatePlayerObjectiveStatus(Objective_Status, id);
  };

  const handleUpdatePlayerObjectiveStatusModal = async (
    Objective_Status: string,
    id?: string,
  ) => {
    await updatePlayerObjectiveStatusModal(Objective_Status, id);
  };

  const handleUpdatePreferredContact = async (
    Preferred_contact: string,
    id_status?: string,
  ) => {
    await updatePlayerPreferredContact(Preferred_contact, id_status);
  };

  const handleUpdatePlayerSnowBirdType = async (Snowbird_type: string) => {
    await updatePlayerSnowBirdType(Snowbird_type);
  };

  const handleToggleEdit = () => {
    setToggleEditAssociatedPlayer(prevState => ({
      ...prevState,
      show: !prevState.show,
      value: playerState.associatedPlayerData
        ? playerState.associatedPlayerData.Player_Id
          ? playerState.associatedPlayerData.Player_Id
          : ''
        : '',
    }));
  };

  const handleChangeAssociatedPlayer = ({
    currentTarget,
  }: React.FormEvent<HTMLInputElement>) => {
    setToggleEditAssociatedPlayer(prevState => ({
      ...prevState,
      value: currentTarget.value,
    }));
  };

  const handleBlurChange = () => {
    setToggleEditAssociatedPlayer({
      show: false,
      value: '',
    });
  };

  const handleUpdateAssociatedPlayer = async (remove: boolean) => {
    if (remove) {
      await updateAssociatedPlayer(null);
    } else {
      console.log(
        toggleEditAssociatedPlayer.value,
        playerState.associatedPlayerData?.Player_Id,
      );
      if (
        toggleEditAssociatedPlayer.value !=
        playerState.associatedPlayerData?.Player_Id
      ) {
        console.log(playerState.associatedPlayerData);
        await updateAssociatedPlayer(toggleEditAssociatedPlayer.value);
      }
    }
    setToggleEditAssociatedPlayer(prevState => ({
      ...prevState,
      show: !prevState.show,
    }));
  };

  return (
    <Modal
      level={1}
      opened={isModalOpened}
      toggleModal={handleTogglePlayerDetailsModal}
      id="ModalPlayerDetails"
      size="modal-xl"
      content={
        playersState.isLoadingPlayerData ? (
          <PlayerDetailsSkeleton data={data} />
        ) : (
          <div className="block lg:flex">
            <div className="flex-grow p-3">
              <List>
                <ListItem unique cols={'grid-cols-1'}>
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col">
                      <p className="text-md font-semibold text-blue-500">
                        {data.First_Name} {data.Name}
                      </p>
                      <div className="text-sm text-gray-600 ">
                        Player ID: {data.Player_Id}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-700 ">
                        Last contacted:{' '}
                        {playerState.Last_Contacted_Date
                          ? handleFormatDate(playerState.Last_Contacted_Date)
                          : '--/--/----'}
                      </div>
                    </div>
                  </div>
                </ListItem>
                <ListItem unique cols={'grid-cols-4'} title={'Player Info'}>
                  <div className={'xl:col-span-1 col-span-2 '}>
                    <div className="text-xs text-gray-500 pb-1">Home phone</div>
                    <p className="text-sm text-blue-500">
                      {playerState.Home_Phone
                        ? formatPhoneNumber(playerState.Home_Phone)
                        : '---'}
                    </p>
                  </div>
                  <div className={'xl:col-span-1 col-span-2 '}>
                    <div className="text-xs text-gray-500 pb-1">
                      Mobile phone
                    </div>
                    <p className="text-sm text-blue-500">
                      {playerState.Mobile_Phone
                        ? formatPhoneNumber(playerState.Mobile_Phone)
                        : '---'}
                    </p>
                  </div>
                  <div className={'xl:col-span-1 col-span-2  break-all'}>
                    <div className="text-xs text-gray-500 pb-1">Email</div>
                    <p className="text-sm text-blue-500">
                      {playerState.Email ? playerState.Email : '---'}
                    </p>
                  </div>
                  <div className={'xl:col-span-1 col-span-2 grid items-center'}>
                    <div className="text-xs text-gray-500 pb-1">
                      Preferred contact
                    </div>

                    {isObjectivesView ? (
                      <DropdownContainer
                        full
                        outlined
                        shadow={false}
                        size="xsmall"
                        status="default"
                        options={['- None -', 'Call', 'Text', 'Email']}
                        loading={data.Preferred_Contact_State?.is_loading}
                        loadingText={'Updating...'}
                        selected={
                          data.Preferred_Contact_State
                            ? data.Preferred_Contact_State.name
                            : '- None -'
                        }
                        onChangeSelected={Preferred_contact =>
                          handleUpdatePreferredContact(
                            Preferred_contact,
                            data.id_status,
                          )
                        }
                      />
                    ) : (
                      <DropdownContainer
                        full
                        outlined
                        shadow={false}
                        size="xsmall"
                        status="default"
                        options={['- None -', 'Call', 'Text', 'Email']}
                        loading={
                          playerState.Preferred_Contact_State?.is_loading
                        }
                        loadingText={'Updating...'}
                        selected={
                          playerState.Preferred_Contact_State
                            ? playerState.Preferred_Contact_State.name
                            : '- None -'
                        }
                        onChangeSelected={Preferred_contact =>
                          handleUpdatePreferredContact(Preferred_contact)
                        }
                      />
                    )}
                  </div>
                </ListItem>
                <ListItem unique cols={'grid-cols-4'} title={'Contact flags'}>
                  <div className={'col-span-2 xl:col-span-1'}>
                    <div className="text-xs text-gray-500 pb-1">Zip code</div>
                    <p className="text-sm text-blue-500">
                      {playerState.Zip_Code ? playerState.Zip_Code : '---'}
                    </p>
                  </div>
                  <div className={'col-span-2 xl:col-span-1'}>
                    <div className="text-xs text-gray-500 pb-1">Birthday</div>
                    <p className="text-sm text-blue-500">
                      {playerState.Birth_Month &&
                        playerState.Birth_Date &&
                        playerState.Birth_Year
                        ? playerState.Birth_Month +
                        '/' +
                        playerState.Birth_Date +
                        '/' +
                        playerState.Birth_Year
                        : '--/--/---'}
                    </p>
                  </div>
                  <div className={'col-span-2 xl:col-span-1'}>
                    <div className="text-xs text-gray-500 pb-1">
                      Associated player ID
                    </div>
                    {toggleEditAssociatedPlayer.show ? (
                      <OutsideAlerter onClick={handleBlurChange}>
                        <div className="flex lg:block ">
                          <div className="flex-grow">
                            <TextInput
                              autoFocus
                              value={toggleEditAssociatedPlayer.value}
                              placeholder="Player ID"
                              size="xsmall"
                              type="number"
                              onChange={handleChangeAssociatedPlayer}
                            />
                          </div>
                          <div className="flex justify-between">
                            <ButtonCustom
                              outlined
                              disabled={
                                playerState.Associated_Player?.is_loading ||
                                toggleEditAssociatedPlayer.value.length === 0
                              }
                              loading={
                                playerState.Associated_Player?.is_loading
                              }
                              loadingText={' '}
                              status="danger"
                              size="xsmall"
                              tooltip="Remove"
                              iconLeft={
                                <TrashIcon className="w-4 h-4 stroke-2" />
                              }
                              onClick={() => handleUpdateAssociatedPlayer(true)}
                            />
                            <ButtonCustom
                              outlined
                              disabled={
                                playerState.Associated_Player?.is_loading ||
                                toggleEditAssociatedPlayer.value.length === 0
                              }
                              loading={
                                playerState.Associated_Player?.is_loading
                              }
                              loadingText={' '}
                              status="success"
                              size="xsmall"
                              tooltip="Save"
                              iconLeft={
                                <CheckIcon className="w-4 h-4 stroke-2" />
                              }
                              onClick={() =>
                                handleUpdateAssociatedPlayer(false)
                              }
                            />
                          </div>
                        </div>
                      </OutsideAlerter>
                    ) : playerState.associatedPlayerData ? (
                      <div className="flex items-center gap-2">
                        <div
                          className="flex items-center gap-1 text-blue-500  cursor-pointer max-w-fit"
                          onClick={() =>
                            window.open(
                              `/search/${playerState.associatedPlayerData?.Player_Id}`,
                              '_blank',
                            )
                          }>
                          {/* <SearchIcon
                            xlinkTitle="a"
                            className="w-4 h-4 text-blue-500 stroke-[2]"
                            too
                          />
                          <div
                            className="text-sm border-b border-b-blue-500"
                          >
                            {playerState.associatedPlayerData?.First_Name}{' '}
                            {playerState.associatedPlayerData?.Name}
                          </div> */}
                          <ButtonCustom
                            outlined
                            title={
                              playerState.associatedPlayerData?.First_Name +
                              ' ' +
                              playerState.associatedPlayerData?.Name
                            }
                            status="default"
                            size="xsmall"
                            tooltip="Search player"
                            iconLeft={
                              <SearchIcon className="w-4 h-4 stroke-2" />
                            }
                          />
                        </div>
                        <ButtonCustom
                          outlined
                          loadingText={' '}
                          status="primary"
                          size="xsmall"
                          tooltip="Update"
                          iconLeft={<PencilIcon className="w-4 h-4 stroke-2" />}
                          onClick={handleToggleEdit}
                        />
                      </div>
                    ) : (
                      <div
                        className="flex items-center gap-1 text-blue-500 cursor-pointer max-w-fit"
                        onClick={handleToggleEdit}>
                        <PlusIcon className="w-3 h-3 text-blue-500 stroke-[3]" />
                        <div className="text-sm">Add player</div>
                      </div>
                    )}
                  </div>
                  <div className={'col-span-2 xl:col-span-1 grid items-center'}>
                    <div className="text-xs text-gray-500 pb-1">S/T</div>
                    <DropdownContainer
                      full
                      outlined
                      shadow={false}
                      size="xsmall"
                      status="default"
                      options={['- None -', 'Snowbird', 'Tourist']}
                      selected={
                        playerState.Snowbird_State?.name === 'S'
                          ? 'Snowbird'
                          : data.Snowbird === 'T'
                            ? 'Tourist'
                            : '- None -'
                      }
                      loading={playerState.Snowbird_State?.is_loading}
                      loadingText={'Updating...'}
                      onChangeSelected={Snowbird_type =>
                        handleUpdatePlayerSnowBirdType(Snowbird_type)
                      }
                    />
                  </div>
                </ListItem>
                <ListItem unique cols={'grid-cols-4'} title={'Reward Info'}>
                  <div className={'col-span-2 lg:col-span-1'}>
                    <div className="text-xs text-gray-500 pb-1">Membership</div>
                    <p className="text-sm text-blue-500">
                      {playerState.Membership_Status && playerState.Marketing_Tier
                        ? `${playerState.Membership_Status} - ${playerState.Marketing_Tier}`
                        : '---'}
                    </p>
                  </div>
                  <div className={'col-span-2 lg:col-span-1'}>
                    <div className="text-xs text-gray-500 pb-1">
                      Current Reward
                    </div>
                    <p className="text-sm text-blue-500">
                      {playerState.Current_Cycle_Reward
                        ? playerState.Current_Cycle_Reward
                        : '---'}
                    </p>
                  </div>
                  <div className={'col-span-2 lg:col-span-1'}>
                    <div className="text-xs text-gray-500 pb-1">
                      {/* Bounce Back - Epx Date - Type */}
                      Type : Bounce Back - Exp Date
                    </div>
                    <p className="text-sm text-blue-500">
                      {playerState.Pit_BB_Dollar_Value &&
                        playerState.Pit_BB_Expiration_Date
                        ? `Pit: ${handleFormatMoney(
                          playerState.Pit_BB_Dollar_Value,
                        )} - ${handleFormatDate(
                          playerState.Pit_BB_Expiration_Date,
                        )}`
                        : 'Pit: no pit value'}
                      <br />
                      {playerState.Slot_BB_Dollar_Value &&
                        playerState.Slot_BB_Expiration_Date
                        ? `Slot: ${handleFormatMoney(
                          playerState.Slot_BB_Dollar_Value,
                        )} - ${handleFormatDate(
                          playerState.Slot_BB_Expiration_Date,
                        )}`
                        : 'Slot: no slot value'}
                    </p>
                  </div>
                  <div className={'col-span-2 lg:col-span-1 grid items-center'}>
                    <div className="text-xs text-gray-500 pb-1">
                      Reward Up/Down
                    </div>
                    <p className="text-sm text-blue-500">
                      {calcUpDown(
                        playerState.Current_Cycle_Reward,
                        playerState.Prior_Cycle_Reward,
                      )}
                    </p>
                  </div>
                </ListItem>
                {isObjectivesView && (
                  <ListItem
                    unique
                    cols={'grid-cols-4'}
                    title={'Objective info'}>
                    <div className={'col-span-2 xl:col-span-1'}>
                      <div className="text-xs text-gray-500 pb-1">
                        Status change date
                      </div>
                      <p className="text-sm text-blue-500">
                        {data.Status_Changed_Time
                          ? handleFormatDateTime(data.Status_Changed_Time)
                          : '--/--/----'}
                      </p>
                    </div>
                    <div className={'col-span-2 xl:col-span-1'}>
                      <div className="text-xs text-gray-500 pb-1">
                        Objective status
                      </div>

                      {data.id_status && data.Objective_Status && (
                        <>
                          <DropdownContainer
                            full
                            outlined
                            shadow={false}
                            size="xsmall"
                            status={handleGetStatusType(
                              data.Objective_Status.name,
                            )}
                            selected={data.Objective_Status.name}
                            options={OBJECTIVE_STATUS}
                            loading={data.Objective_Status.is_loading}
                            loadingText={'Updating...'}
                            setStatusOnChangeSelected={handleGetStatusType}
                            onChangeSelected={Objective_status =>
                              handleUpdatePlayerObjectiveStatus(
                                Objective_status,
                                data.id_status,
                              )
                            }
                            updateSelected={data.Objective_Status.name}
                            updateSelectedStatus
                          />
                        </>
                      )}
                    </div>
                    <div className={'col-span-2 xl:col-span-1'}>
                      <div className="text-xs text-gray-500 pb-1">
                        VIP offer
                      </div>
                      <p className="text-sm text-blue-500">
                        {data.VIP_Offer ? data.VIP_Offer : '---'}
                      </p>
                    </div>
                    <div className={'col-span-2 xl:col-span-1'}>
                      <div className="text-xs text-gray-500 pb-1">
                        VIP decile
                      </div>
                      <p className="text-sm text-blue-500">
                        {data.VIP_Decile ? data.VIP_Decile : '---'}
                      </p>
                    </div>
                  </ListItem>
                )}
              </List>

              <div className="bg-gray-50 rounded-md">
                <Tabs
                  type="tabs"
                  align="right"
                  selected="gaming-data"
                  links={[
                    { id: 'gaming-data', title: 'Gaming data' },
                    {
                      id: 'reservations',
                      title: `Reservations (${playerState.upcomingReservationInformation
                          ? playerState.upcomingReservationInformation.length
                          : 0
                        })`,
                    },
                    {
                      id: 'object-list',
                      title: `Objective list (${playerState.PlayerObjectiveStatuses
                          ? playerState.PlayerObjectiveStatuses.length
                          : 0
                        })`,
                    },
                  ]}
                  contents={[
                    {
                      content: (
                        <div className="p-2 overflow-auto">
                          <div className="flex gap-3">
                            <div>
                              <div className="text-xs text-gray-500 pb-1">
                                First gaming date
                              </div>
                              <p className="text-sm text-blue-500">
                                {playerState.First_Gaming_Date
                                  ? handleFormatDate(
                                    playerState.First_Gaming_Date,
                                  )
                                  : '--/--/----'}
                              </p>
                            </div>
                            <div>
                              <div className="text-xs text-gray-500 pb-1">
                                Last gaming date
                              </div>
                              <p className="text-sm text-blue-500">
                                {playerState.Last_Gaming_Date
                                  ? handleFormatDate(
                                    playerState.Last_Gaming_Date,
                                  )
                                  : '--/--/----'}
                              </p>
                            </div>
                            <div>
                              <div className="text-xs text-gray-500 pb-1">
                                Gaming preference
                              </div>
                              <p className="text-sm text-blue-500">
                                {playerState.Gaming_Preference}
                              </p>
                            </div>
                          </div>
                          <table className="w-full border text-left">
                            <thead className="bg-gray-200 w-full">
                              <tr className="text-xs ">
                                <th></th>
                                <th>Current</th>
                                <th>L3M</th>
                                <th>L6M</th>
                                <th>L12M</th>
                                <th>L24M</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="!text-xs border">
                                <td className="bg-gray-200 text-center">
                                  Trips
                                </td>
                                <td>
                                  {playerState.Current_Month_Trips
                                    ? playerState.Current_Month_Trips
                                    : '0'}
                                </td>
                                <td>
                                  {playerState.L3M_Trips
                                    ? playerState.L3M_Trips
                                    : '0'}
                                </td>
                                <td>
                                  {playerState.L6M_Trips
                                    ? playerState.L6M_Trips
                                    : '0'}
                                </td>
                                <td>
                                  {playerState.Last_12_Month_Trips
                                    ? playerState.Last_12_Month_Trips
                                    : '0'}
                                </td>
                                <td>
                                  {playerState.L24M_Trips
                                    ? playerState.L24M_Trips
                                    : '0'}
                                </td>
                              </tr>
                              <tr className="!text-xs border">
                                <td className="bg-gray-200 text-center">ADT</td>
                                <td>
                                  {handleFormatMoney(
                                    playerState.Current_Month_ADT,
                                  )}
                                </td>
                                <td>
                                  {handleFormatMoney(playerState.L3M_ADT)}
                                </td>
                                <td>
                                  {handleFormatMoney(playerState.L6M_ADT)}
                                </td>
                                <td>
                                  {handleFormatMoney(
                                    playerState.Last_12_Month_ADT,
                                  )}
                                </td>
                                <td>
                                  {handleFormatMoney(playerState.L24M_ADT)}
                                </td>
                              </tr>
                              <tr className="!text-xs border">
                                <td className="bg-gray-200 text-center">
                                  Actual
                                </td>
                                <td>
                                  {handleFormatMoney(
                                    playerState.Current_Month_Actual,
                                  )}
                                </td>
                                <td>
                                  {handleFormatMoney(playerState.L3M_Actual)}
                                </td>
                                <td>
                                  {handleFormatMoney(playerState.L6M_Actual)}
                                </td>
                                <td>
                                  {handleFormatMoney(
                                    playerState.Last_12_Month_Actual,
                                  )}
                                </td>
                                <td>
                                  {handleFormatMoney(playerState.L24M_Actual)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ),
                    },
                    {
                      content: (
                        <div className="p-2 overflow-auto">
                          {playerState.upcomingReservationInformation ? (
                            <table className="w-full border text-left">
                              <thead className="bg-gray-200 w-full">
                                <tr className="text-sm ">
                                  <th className="text-center">Cruise Date</th>
                                  <th>AM/PM</th>
                                  <th className="min-w-[30px]">Bus</th>
                                  <th>Offer</th>
                                  <th>Status</th>
                                  <th>Guest Name</th>
                                  <th>Guest Phone</th>
                                  <th>Guest PlayerID</th>
                                  <th>Deposit</th>
                                </tr>
                              </thead>
                              <tbody className="bold">
                                {playerState.upcomingReservationInformation
                                  ?.sort(
                                    (
                                      a: ReservationRecord,
                                      b: ReservationRecord,
                                    ) => {
                                      return (
                                        Date.parse(b.Cruise_Date) -
                                        Date.parse(a.Cruise_Date)
                                      );
                                    },
                                  )
                                  .map((reservation, key) => (
                                    <tr
                                      key={`reservation_${key}`}
                                      className="text-sm border">
                                      <td className=" text-center">
                                        {handleFormatDate(
                                          reservation.Cruise_Date,
                                        )}
                                      </td>
                                      <td>
                                        {reservation.Cruise_Time
                                          ? reservation.Cruise_Time
                                          : '---'}
                                      </td>
                                      <td>
                                        {reservation.Transportation
                                          ? reservation.Transportation
                                          : '-'}
                                      </td>
                                      <td>
                                        {reservation.Offer
                                          ? reservation.Offer
                                          : '---'}
                                      </td>
                                      <td>
                                        {reservation.Status
                                          ? reservation.Status
                                          : '---'}
                                      </td>
                                      <td>
                                        {reservation.Guest_Name
                                          ? reservation.Guest_Name
                                          : '---'}
                                      </td>
                                      <td>
                                        {reservation.Guest_Phone_Number
                                          ? reservation.Guest_Phone_Number
                                          : '---'}
                                      </td>
                                      <td>
                                        {reservation.Guest_Player_Id
                                          ? reservation.Guest_Player_Id
                                          : '---'}
                                      </td>
                                      <td>
                                        {handleFormatMoney(
                                          reservation.Deposit_Amount,
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="flex flex-col justify-center items-center text-gray-500 select-none">
                              <ClipboardListIcon className="w-8 h-8 stroke-[1]" />
                              <p className="text-sm ">
                                This player has no upcoming reservations
                              </p>
                            </div>
                          )}
                        </div>
                      ),
                    },
                    {
                      content: (
                        <div className="p-2 max-h-[400px]">
                          {playerState.PlayerObjectiveStatuses &&
                            playerState.PlayerObjectiveStatuses.length > 0 ? (
                            <table className="w-full border text-left">
                              <thead className="bg-gray-200 w-full">
                                <tr className="text-sm ">
                                  <th className="text-center">#</th>
                                  <th>Name</th>
                                  <th>End date</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {playerState.PlayerObjectiveStatuses.map(
                                  (objective, key) => (
                                    <tr
                                      key={`objective_${key}`}
                                      className="text-sm border">
                                      <td className=" text-center">
                                        {key + 1}
                                      </td>
                                      <td>{objective.Objective.name}</td>
                                      <td>
                                        {handleFormatDate(
                                          objective.Host_End_Date,
                                        )}
                                      </td>
                                      <td>
                                        <DropdownContainer
                                          outlined
                                          full
                                          size="xsmall"
                                          selected={objective.Objective_Status}
                                          status={handleGetStatusType(
                                            objective.Objective_Status,
                                          )}
                                          loading={objective.is_loading}
                                          loadingText={'Updating...'}
                                          setStatusOnChangeSelected={
                                            handleGetStatusType
                                          }
                                          onChangeSelected={Objective_status => {
                                            handleUpdatePlayerObjectiveStatusModal(
                                              Objective_status,
                                              objective.id,
                                            );
                                          }}
                                          updateSelected={
                                            objective.Objective_Status
                                          }
                                          updateSelectedStatus
                                          options={OBJECTIVE_STATUS}
                                        />
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </table>
                          ) : (
                            <div className="flex flex-col justify-center items-center text-gray-500 select-none">
                              <ClipboardListIcon className="w-8 h-8 stroke-[1]" />
                              <p className="text-sm">
                                This player has no assigned objectives
                              </p>
                            </div>
                          )}
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <Notes notes={playerState.Notes} />
            </div>
          </div>
        )
      }
      footer={
        <>
          <ButtonCustom
            rounded
            iconLeft={<XIcon className="w-4 h-4" />}
            size="small"
            outlined
            title="Close"
            status="primary"
            onClick={() => handleTogglePlayerDetailsModal()}
          />
          <ButtonCustom
            rounded
            iconLeft={<CheckIcon className="w-4 h-4 stroke-2" />}
            size="small"
            title="Save"
            status="success"
            onClick={() => handleTogglePlayerDetailsModal()}
          />
        </>
      }
    />
  );
};

export default PlayerDetailsModal;
