import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardListIcon,
} from '@heroicons/react/outline';
import React from 'react';
import { Column, useGlobalFilter, usePagination, useTable } from 'react-table';
import ButtonCustom from '../Button/Button';
import GlobalFilter from './GlobalFilter';
import './TableCustom.module.scss';

interface TableCustomProps {
  columns: Array<Column>;
  data: object[];
  contentBL?: React.ReactNode;
  contentTL?: React.ReactNode;
  showSetPageSize?: boolean;
  showPagination?: boolean;
  showGlobalSearch?: boolean;
  hiddenColumns?: Array<string>;
  showAsyncPagination?: boolean;
  handleAsyncPagination?: () => void;
  pageNumber?: number;
  isLoadingMore?: boolean;
  totalItems: number;
  itemsPerPage: number;
  emptyText?: string;
}

const TableCustom = ({ pageNumber = 1, ...props }: TableCustomProps) => {
  const columns = React.useMemo<Array<Column>>(
    () => props.columns,
    [props.columns],
  );

  const data = React.useMemo<object[]>(() => props.data, [props.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: props.itemsPerPage,
        hiddenColumns: props.hiddenColumns ? props.hiddenColumns : [],
      },
    },
    useGlobalFilter,
    usePagination,
  );

  const handleAsyncPagination = () => {
    if (props.handleAsyncPagination) {
      setPageSize(Number(props.itemsPerPage * (pageNumber + 1)));
      props.handleAsyncPagination();
    }
  };

  return (
    <>
      <div className="flex justify-between items-center gap-2">
        {props.showSetPageSize ? (
          <div className="w-28">
            <select
              className="form-select "
              value={state.pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}>
              {[props.itemsPerPage].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="text-sm text-gray-500">{props.contentTL}</div>
        )}
        {props.showGlobalSearch && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      </div>
      <div className="mb-2">
        <table {...getTableProps()} className="w-full my-3 ">
          <thead className="text-left">
            {headerGroups.map((headerGroup, key) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`header_${key}`}>
                {headerGroup.headers.map((column, keygroup) => (
                  <th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                    key={`headerGroup_${keygroup}`}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data.length > 0 ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row, rowkey) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={`row_${rowkey}`}
                    className="border-b">
                    {row.cells.map((cell, cellkey) => {
                      return (
                        <td
                          {...cell.getCellProps({})}
                          key={`cell_${cellkey}`}
                          className="py-4">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-0 py-3 text-gray-500">
                  <div className="flex flex-col items-center justify-center gap-2">
                    <ClipboardListIcon className="w-12 h-12 stroke-[1]" />
                    <span className="text-base">
                      {props.emptyText ? props.emptyText : 'No records found'}
                    </span>
                    <ButtonCustom
                      outlined
                      ripple
                      status="primary"
                      title="Reload page"
                      size="xsmall"
                      onClick={() => window.location.reload()}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      {data.length > 0 ? (
        <div className="flex flex-wrap items-center justify-between ">
          <div>{props.contentBL && props.contentBL}</div>
          {props.showAsyncPagination ? (
            <div className="flex gap-1 items-center">
              <ButtonCustom
                status="primary"
                title="Load more"
                iconLeft={
                  pageNumber * props.itemsPerPage >= props.totalItems ||
                    props.isLoadingMore ? (
                    <ChevronDownIcon className={'w-4 h-4 text-grey-300 '} />
                  ) : (
                    <ChevronDownIcon
                      className={'w-4 h-4 text-white stroke-2'}
                    />
                  )
                }
                size="xsmall"
                loading={props.isLoadingMore}
                loadingText={'Loading'}
                onClick={() => handleAsyncPagination()}
                disabled={
                  pageNumber * props.itemsPerPage >= props.totalItems ||
                  props.isLoadingMore
                }
              />
            </div>
          ) : (
            props.showPagination && (
              <div className="flex gap-1 items-center">
                <span className="text-sm">
                  Page {state.pageIndex + 1} of {pageOptions.length}
                </span>
                {pageCount > 1 && (
                  <>
                    <ButtonCustom
                      status="default"
                      outlined
                      iconLeft={
                        <ChevronDoubleLeftIcon
                          className={'w-4 h-4 text-blue-500'}
                        />
                      }
                      size="xsmall"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    />
                    <ButtonCustom
                      status="default"
                      outlined
                      iconLeft={
                        <ChevronLeftIcon className={'w-4 h-4 text-blue-500'} />
                      }
                      size="xsmall"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    />
                    <ButtonCustom
                      status="default"
                      outlined
                      iconLeft={
                        <ChevronRightIcon className={'w-4 h-4 text-blue-500'} />
                      }
                      size="xsmall"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    />
                    <ButtonCustom
                      status="default"
                      outlined
                      iconLeft={
                        <ChevronDoubleRightIcon
                          className={'w-4 h-4 text-blue-500'}
                        />
                      }
                      size="xsmall"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    />
                  </>
                )}
              </div>
            )
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TableCustom;
