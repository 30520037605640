import endpoints from 'constants/endpoints';
import { fetchSafe } from 'helpers/firebaseSetup';
import { RootStateOrAny } from 'react-redux';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionTypesSearch } from 'state/action-types/searchPlayer';
import { PlayerData } from 'state/interfaces';
import { RootState } from 'state/store';
import * as Actions from '../actions';

// Action creators
export const setSearchData = (payload: {
  data: PlayerData[];
  page: number;
  totalItems: number;
}): ActionTypesSearch => ({
  type: Actions.SET_SEARCH_DATA,
  payload,
});

export const toggleSearchingPlayer = (payload: boolean): ActionTypesSearch => ({
  type: Actions.TOGGLE_SEARCHING_PLAYER,
  payload,
});

export const toggleLoadingMore = (payload: boolean): ActionTypesSearch => ({
  type: Actions.TOGGLE_LOADING_MORE,
  payload,
});

// get search results
export const getSearhResults =
  (
    First_Name: string,
    Name: string,
    Player_Id: string,
    page: number,
    itemsPerPage: number,
    loadMore: boolean,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    if (loadMore) dispatch(toggleLoadingMore(true));
    else {
      dispatch(toggleSearchingPlayer(true));
      dispatch(setSearchData({ data: [], page: 1, totalItems: 0 }));
    }
    return await fetchSafe({
      endpoint: endpoints.zoho.searchPlayer,
      page,
      itemsPerPage,
      First_Name: First_Name ? First_Name : null,
      Name: Name ? Name : null,
      Player_Id: Player_Id ? Player_Id : null,
    })
      .then(res => {
        const data = <RootStateOrAny>res.data;
        if (data.code) {
          dispatch(setSearchData({ data: [], page: 1, totalItems: 0 }));
        } else {
          if (loadMore) {
            const searchData = getState().search.searchData;
            const playerList = <PlayerData[]>[
              ...searchData,
              ...data.playerList,
            ];

            dispatch(
              setSearchData({
                data: playerList.map(player => {
                  return {
                    ...player,
                    Full_Name: player.First_Name + ' ' + player.Name,
                  };
                }),
                page,
                totalItems: data.Count,
              }),
            );
            dispatch(toggleLoadingMore(false));
          } else {
            dispatch(
              setSearchData({
                data: (<PlayerData[]>data.playerList).map(player => {
                  return {
                    ...player,
                    Full_Name: player.First_Name + ' ' + player.Name,
                  };
                }),
                page,
                totalItems: data.Count,
              }),
            );
          }
        }
        dispatch(toggleSearchingPlayer(false));
      })
      .catch(error => {
        console.log(error);
        dispatch(toggleSearchingPlayer(false));
      });
  };
