import React from 'react';

interface SideNavSectionProps {
  title?: string;
  children: React.ReactNode;
}

const SideNavSection = ({ title, children }: SideNavSectionProps) => {
  return (
    <>
      <hr className="my-2" />
      <ul className="relative p-4 navSection">
        <p className="text-gray-400 px-6">{title}</p>
        {children}
      </ul>
    </>
  );
};

export default SideNavSection;
