import {
  Data,
  AuthStore,
  DashboardStore,
  PlayersStore,
  SearchPlayerStore,
} from '../state/interfaces';

export const DATA_INITIAL_STATE: Data = {
  id: '',
  Google_Auth_UID: '',
  Email: '',
  Phone: '',
  Name: '',
  Host_ID: '',
};

export const AUTH_INITIAL_STATE: AuthStore = {
  userData: DATA_INITIAL_STATE,
  loginInfo: {
    errorMessage: '',
    verificationCode: '',
    verificationId: '',
    MFA: false,
  },
  isLoadingLogin: false,
  isLoadingResetPass: false,
  isLoadingLogout: false,
};

export const DASHBOARD_INITIAL_STATE: DashboardStore = {
  objectives: [],
  events: [],
  isLoadingDashboard: false,
  isLoadingObjectives: false,
  isLoadingEvents: false,
};

export const PLAYERS_INITIAL_STATE: PlayersStore = {
  playersData: {
    objectiveInformation: { Name: '', Objective_Type: '', Created_Time: '' },
    playerList: [],
    page: 1,
    Count: 0,
  },
  playerData: {
    Current_Cycle_Reward: null,
    Prior_Cycle_Reward: null,
  },
  isLoadingPlayers: false,
  isLoadingPlayerData: false,
  isUpdatingNote: false,
  isCreatingNote: false,
  isDeletingNote: false,
};

export const SEARCH_PLAYER_INITIAL_STATE: SearchPlayerStore = {
  searchData: [],
  page: 1,
  totalItems: 0,
  isSearching: false,
  isLoadingMore: false,
};

export enum Size {
  XS = 'xsmall',
  MEDIUM = 'medium',
  SMALL = 'small',
  LARGE = 'large',
}

export enum Status {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DEFAULT = 'default',
  WARNING = 'warning',
  DANGER = 'danger',
  MAGIC = 'magic',
  LOVE = 'love',
  POWERFUL = 'powerful',
  RELAX = 'relax',
  DARK = 'dark',
  AMBER = 'amber',
}

export const HEADER_VALUES = [
  'Added',
  'Last contacted',
  'Player name',
  'Phone',
  'Email',
  'Zip',
  'Preferred contact',
  'Objective status',
  'Details',
];

export enum ObjectiveStatus {
  NOT_CONTACTED = 'Not Contacted',
  RESERVATION_MADE = 'Reservation Made',
  CALL_BACK = 'Call Back',
  LEFT_MESSAGE = 'Left Message',
  BAD_PHONE_NUMBER = 'Bad Phone Number',
  // MAILBOX_FULL = 'Mailbox Full',
  SICK = 'Sick',
  OUT_OF_TOWN = 'Out of Town',
  PANDEMIC = 'Pandemic',
  NO_VOICE_MAIL = 'No Voice Mail',
  HANG_UP = 'Hang Up',
  NOT_INTERESTED = 'Not Interested',
  DO_NOT_CONTACT = 'Do Not Contact',
}

export const OBJECTIVE_STATUS = [
  ObjectiveStatus.NOT_CONTACTED,
  ObjectiveStatus.RESERVATION_MADE,
  ObjectiveStatus.CALL_BACK,
  ObjectiveStatus.LEFT_MESSAGE,
  ObjectiveStatus.NOT_INTERESTED,
  ObjectiveStatus.BAD_PHONE_NUMBER,
  // ObjectiveStatus.MAILBOX_FULL,
  ObjectiveStatus.SICK,
  ObjectiveStatus.OUT_OF_TOWN,
  ObjectiveStatus.PANDEMIC,
  ObjectiveStatus.NO_VOICE_MAIL,
  ObjectiveStatus.HANG_UP,
  ObjectiveStatus.DO_NOT_CONTACT,
];

export const LINKS_ITEMS = [
  { id: 'all', title: 'All' },
  { id: 'not-contacted', title: ObjectiveStatus.NOT_CONTACTED },
  { id: 'reservation-made', title: ObjectiveStatus.RESERVATION_MADE },
  { id: 'call-back', title: ObjectiveStatus.CALL_BACK },
  { id: 'left-message', title: ObjectiveStatus.LEFT_MESSAGE },
  { id: 'not-interested', title: ObjectiveStatus.NOT_INTERESTED },
  { id: 'bad-phone', title: ObjectiveStatus.BAD_PHONE_NUMBER },
  // { id: 'mailbox-full', title: ObjectiveStatus.MAILBOX_FULL },
  { id: 'sick', title: ObjectiveStatus.SICK },
  { id: 'pandemic', title: ObjectiveStatus.PANDEMIC },
  { id: 'out-of-town', title: ObjectiveStatus.OUT_OF_TOWN },
  { id: 'no-voice-mail', title: ObjectiveStatus.NO_VOICE_MAIL },
  { id: 'hung-up', title: ObjectiveStatus.HANG_UP },
  { id: 'do-not-contact', title: ObjectiveStatus.DO_NOT_CONTACT },
];

export enum Spinners {
  BORDER = 'border',
  GROW = 'grow',
}

export const handleGetStatusType = (value: string) => {
  switch (value) {
    case ObjectiveStatus.RESERVATION_MADE:
      return Status.SUCCESS;
    // case ObjectiveStatus.MAILBOX_FULL:
    //   return Status.PRIMARY;
    case ObjectiveStatus.BAD_PHONE_NUMBER:
      return Status.DANGER;
    case ObjectiveStatus.SICK:
      return Status.WARNING;
    case ObjectiveStatus.PANDEMIC:
      return Status.LOVE;
    case ObjectiveStatus.OUT_OF_TOWN:
      return Status.RELAX;
    case ObjectiveStatus.NO_VOICE_MAIL:
      return Status.MAGIC;
    case ObjectiveStatus.HANG_UP:
      return Status.POWERFUL;
    case ObjectiveStatus.CALL_BACK:
      return Status.PRIMARY;
    case ObjectiveStatus.LEFT_MESSAGE:
      return Status.AMBER;
    case ObjectiveStatus.NOT_INTERESTED:
      return Status.DARK;
    case ObjectiveStatus.DO_NOT_CONTACT:
      return Status.DARK;
    default:
      return Status.DEFAULT;
  }
};
