import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import DashboardReducer from './DashboardReducer';
import PlayersReducer from './PlayersReducer';
import SearchPlayerReducer from './SearchPlayerReducer';

const reducers = combineReducers({
  auth: AuthReducer,
  dashboard: DashboardReducer,
  players: PlayersReducer,
  search: SearchPlayerReducer,
});
export default reducers;
