import { SearchCircleIcon, SearchIcon } from '@heroicons/react/outline';
import IndeterminateProgressBar from 'components/common/IndeterminateProgressBar/IndeterminateProgressBar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreatorsPlayers, actionCreatorsSearchPlayer } from 'state';
import { RootState } from 'state/store';
import PlayerDetailsModal from 'views/Objectives/PlayerDetailsModal';
import ButtonCustom from '../../components/common/Button/Button';
import Card from '../../components/common/Card/Card';
import TextInput from '../../components/common/TextInput/TextInput';
import SearchPlayersTable from './SearchPlayersTable';

const SearchView = () => {
  const searchState = useSelector((state: RootState) => state.search);
  const playersState = useSelector((state: RootState) => state.players);
  const dispatch = useDispatch();
  const { getSearhResults } = bindActionCreators(
    actionCreatorsSearchPlayer,
    dispatch,
  );
  const { getSpecificPlayerData } = bindActionCreators(
    actionCreatorsPlayers,
    dispatch,
  );

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    playerID: '',
  });
  const [modalPlayerDetails, setModalPlayerDetails] = useState({
    show: false,
    id_row: 0,
    zohoPlayerId: '',
  });
  const { playerID } = useParams();

  const handleTogglePlayerDetailsModal = (
    id_row?: number,
    zohoPlayerId?: string,
  ) => {
    if (id_row !== undefined && zohoPlayerId !== undefined) {
      setModalPlayerDetails(prevState => ({
        show: !prevState.show,
        id_row,
        zohoPlayerId,
      }));
      getSpecificPlayerData(zohoPlayerId);
    } else {
      setModalPlayerDetails(prevState => ({
        show: !prevState.show,
        id_row: 0,
        zohoPlayerId: '',
      }));
    }
  };

  const handleSearch = async (playerID: string) => {
    (document.getElementById('playerID') as HTMLInputElement).value = playerID;
    await getSearhResults('', '', playerID, searchState.page, 20, false);
  };

  const handleSubmitSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      firstName: HTMLInputElement;
      lastName: HTMLInputElement;
      playerID: HTMLInputElement;
    };
    const firstName = formElements.firstName.value.trim();
    const lastName = formElements.lastName.value.trim();
    const playerID = formElements.playerID.value.trim();
    if (!firstName && !lastName && !playerID)
      alert('You need to type first name, last name or the player id');
    else {
      setFormData({ firstName, lastName, playerID });
      await getSearhResults(firstName, lastName, playerID, 1, 20, false);
    }
  };

  const handleAsyncPagination = async () => {
    await getSearhResults(
      formData.firstName,
      formData.lastName,
      formData.playerID,
      searchState.page + 1,
      20,
      true,
    );
  };

  useEffect(() => {
    if (playerID) {
      handleSearch(playerID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerID]);

  return (
    <>
      <IndeterminateProgressBar
        show={
          searchState.isSearching ||
          searchState.isLoadingMore ||
          playersState.isLoadingPlayerData ||
          playersState.isUpdatingNote ||
          playersState.isCreatingNote ||
          playersState.isDeletingNote
        }
      />
      <Card title="Search Players" container fill>
        <form onSubmit={handleSubmitSearch}>
          <div className="grid grid-cols-6 gap-2 mb-2">
            <div className="col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-1">
              <TextInput
                uncontrolled
                id="firstName"
                value={''}
                disabled={searchState.isSearching}
                label="First Name"
                rounded
                size="xsmall"
                placeholder="Enter first name"
              />
            </div>
            <div className="col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-1">
              <TextInput
                id="lastName"
                uncontrolled
                disabled={searchState.isSearching}
                label="Last Name"
                rounded
                size="xsmall"
                placeholder="Enter last Name"
              />
            </div>
            <div className="col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-1">
              <TextInput
                id="playerID"
                uncontrolled
                disabled={searchState.isSearching}
                label="Player ID (7 digits)"
                rounded
                size="xsmall"
                placeholder="Enter player ID"
              />
            </div>
            <div className="col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-1 ">
              <ButtonCustom
                disabled={searchState.isSearching}
                type={'submit'}
                full
                spaceLabel
                rounded
                iconLeft={<SearchIcon className="w-4 h-4" />}
                size="small"
                status="primary"
                title="Search player"
                loading={searchState.isSearching}
                loadingText={'Searching...'}
              />
            </div>
          </div>
        </form>
        <hr />
        <div className="mt-5">
          {searchState.searchData.length === 0 ? (
            <div className="flex flex-col justify-center items-center text-gray-500 text-base">
              <SearchCircleIcon className="w-12 h-12 stroke-[1]" />
              {searchState.isSearching ? (
                <div className="text-center">
                  <p className="font-semibold my-1">Searching for results...</p>
                  <p>We are searching for results with your provided search.</p>
                </div>
              ) : (
                <div className="text-center">
                  <p className="font-semibold my-1">No search results</p>
                  <p>Start by typing first name, last name or player ID</p>
                </div>
              )}
            </div>
          ) : (
            <>
              <SearchPlayersTable
                playerList={searchState.searchData}
                handleAsyncPagination={handleAsyncPagination}
                handleTogglePlayerDetailsModal={handleTogglePlayerDetailsModal}
              />
              {searchState.searchData.length > 0 && (
                <PlayerDetailsModal
                  handleTogglePlayerDetailsModal={
                    handleTogglePlayerDetailsModal
                  }
                  isModalOpened={modalPlayerDetails.show}
                  data={searchState.searchData[modalPlayerDetails.id_row]}
                />
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default SearchView;
