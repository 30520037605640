import React from 'react';
import logo from 'assets/vcclogo.png';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { ShieldCheckIcon } from '@heroicons/react/outline';

const Header = () => {
  const authState = useSelector((state: RootState) => state.auth);
  return (
    <div className="flex flex-row justify-center items-center mb-2">
      <div className="flex-1 flex-col flex mr-3 ">
        <span className="text-lg font-semibold !leading-none">
          Victory Casino Cruises Host Portal
        </span>
        <span className="text-base text-gray-500 ">
          Hi{' '}
          {authState.userData.Name?.split(' ')[0] +
            ' ' +
            authState.userData.Name?.split(' ')[1]}
          , welcome back!
        </span>
      </div>
      <div className="flex-shrink-0 flex items-center gap-2">
        <a href="/profile" className=" flex gap-3 items-center">
          <div className="relative">
            <img
              src={logo}
              className="rounded-full w-10 h-10 object-contain"
              alt="Avatar"
            />
          </div>
          <div className="flex flex-col text-base">
            <span className="font-semibold">
              {authState.userData.Name?.split(' ')[0] +
                ' ' +
                authState.userData.Name?.split(' ')[1]}
            </span>
            <span className="text-gray-500 text-sm leading-none flex gap-1">
              <ShieldCheckIcon className="w-3 h-3 text-green-600" />
              Host
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Header;
