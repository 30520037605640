import React, { useEffect, useState } from 'react';
import Card from '../../components/common/Card/Card';
import Tabs from '../../components/common/Tabs/Tabs';
import { LINKS_ITEMS } from '../../constants/general';
import PlayersTable from './PlayersTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreatorsPlayers } from 'state';
import TableSkeleton from './skeleton/TableSkeleton';
import Spinner from 'components/common/Spinner/Spinner';
import PlayerDetailsModal from './PlayerDetailsModal';
import Button from 'components/common/Button/Button';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import IndeterminateProgressBar from 'components/common/IndeterminateProgressBar/IndeterminateProgressBar';

const itemsPerPage = 50;

const PlayersObjectives = () => {
  const [modalPlayerDetails, setModalPlayerDetails] = useState({
    show: false,
    id_row: 0,
    zohoPlayerId: '',
  });
  const [filter, setFilter] = useState('');
  const playersState = useSelector((state: RootState) => state.players);
  const dispatch = useDispatch();

  const { getListOfPlayersPerObjective, getSpecificPlayerData } =
    bindActionCreators(actionCreatorsPlayers, dispatch);
  const { objectiveId } = useParams();
  const navigate = useNavigate();

  const handleTogglePlayerDetailsModal = (
    id_row?: number,
    zohoPlayerId?: string,
  ) => {
    if (id_row !== undefined && zohoPlayerId !== undefined) {
      setModalPlayerDetails(prevState => ({
        show: !prevState.show,
        id_row,
        zohoPlayerId,
      }));
      getSpecificPlayerData(zohoPlayerId);
    } else {
      setModalPlayerDetails(prevState => ({
        show: !prevState.show,
        id_row: 0,
        zohoPlayerId: '',
      }));
    }
  };

  const handleGetListOfPlayers = async (
    isLoadingMore: boolean,
    page: number,
    filter?: string,
  ) => {
    if (objectiveId) {
      await getListOfPlayersPerObjective(
        objectiveId,
        filter ? true : false,
        filter ? filter : '',
        page,
        // itemsPerPage,
        [itemsPerPage * (page - 1), itemsPerPage],
        isLoadingMore,
      );
    } else navigate('/');
  };

  const handleAsyncPagination = async () => {
    handleGetListOfPlayers(
      true,
      (playersState.playersData.page ? playersState.playersData.page : 0) + 1,
      filter === 'All' ? '' : filter,
    );
  };

  const handleSetFilter = (filter: string) => {
    setFilter(filter);
  };

  useEffect(() => {
    if (filter) {
      if (filter === 'All') handleGetListOfPlayers(false, 1, '');
      else handleGetListOfPlayers(false, 1, filter);
    } else handleGetListOfPlayers(false, 1, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <IndeterminateProgressBar
        show={
          playersState.isLoadingPlayers ||
          playersState.isLoadingPlayerData ||
          playersState.isUpdatingNote ||
          playersState.isCreatingNote ||
          playersState.isDeletingNote
        }
      />
      <Card
        container
        fill
        title={
          playersState.isLoadingPlayers ? (
            <div className="flex items-center gap-2 items">
              <Spinner type="border" className="!h-4 !w-4 !border-2" />
              <span>
                {playersState.playersData.objectiveInformation.Name
                  ? playersState.playersData.objectiveInformation.Name
                  : 'Loading objective...'}
              </span>
            </div>
          ) : (
            playersState.playersData.objectiveInformation.Name
          )
        }
        status="primary">
        <Link to={'/'}>
          <Button
            status="default"
            size="xsmall"
            outlined
            ripple
            title={'Return to dashboard'}
            iconLeft={<ArrowLeftIcon className="w-4 h-4" />}
          />
        </Link>
        <div className="w-full overflow-x-auto px-2 my-5">
          <Tabs
            type="tabs"
            align="left"
            selected="all"
            inline
            links={LINKS_ITEMS}
            onChangeItem={handleSetFilter}
          />
        </div>
        {/* Players custom table */}

        {playersState.isLoadingPlayers ? (
          <TableSkeleton items={5} />
        ) : (
          <PlayersTable
            contentTL={
              <div className=" text-gray-500 text-sm">
                {filter === '' || filter === 'All' ? (
                  <p>Showing all players</p>
                ) : (
                  <p>Showing players by {`"${filter}"`} status</p>
                )}
              </div>
            }
            itemsPerPage={itemsPerPage}
            handleAsyncPagination={handleAsyncPagination}
            objectiveInformation={playersState.playersData.objectiveInformation}
            playerList={playersState.playersData.playerList.map(player => {
              return player.PlayerData;
            })}
            handleTogglePlayerDetailsModal={handleTogglePlayerDetailsModal}
          />
        )}
      </Card>
      {playersState.playersData.playerList.length > 0 && (
        <PlayerDetailsModal
          isObjectivesView
          handleTogglePlayerDetailsModal={handleTogglePlayerDetailsModal}
          isModalOpened={modalPlayerDetails.show}
          data={
            playersState.playersData.playerList.map(player => {
              return player.PlayerData;
            })[modalPlayerDetails.id_row]
          }
        />
      )}
    </>
  );
};

export default PlayersObjectives;
