import endpoints from 'constants/endpoints';
import { fetchSafe } from 'helpers/firebaseSetup';
import * as Actions from '../actions';
import { ActionTypesPlayers } from '../action-types/playerObjectives';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  DataPlayerRecord,
  PlayersData,
  PlayersStore,
  PlayerData,
  NoteRecord,
  ObjectiveRecord,
} from 'state/interfaces';
import { RootState } from 'state/store';
import { RootStateOrAny } from 'react-redux';

// Action creators
export const setPlayersData = (payload: {
  data: PlayersData;
  limit: number;
  page: number;
  Count: number;
}): ActionTypesPlayers => ({
  type: Actions.SET_PLAYERS_DATA,
  payload,
});

export const setPlayerData = (payload: PlayerData): ActionTypesPlayers => ({
  type: Actions.SET_PLAYER_DATA,
  payload,
});

export const resetPlayerData = (): ActionTypesPlayers => ({
  type: Actions.RESET_PLAYERS_DATA,
});

export const toggleLoadingPlayers = (payload: boolean): ActionTypesPlayers => ({
  type: Actions.TOGGLE_LOADING_PLAYERS,
  payload,
});

export const toggleLoadingMorePlayers = (
  payload: boolean,
): ActionTypesPlayers => ({
  type: Actions.TOGGLE_LOADING_MORE_PLAYERS,
  payload,
});

export const toggleLoadingPlayerData = (
  payload: boolean,
): ActionTypesPlayers => ({
  type: Actions.TOGGLE_LOADING_PLAYER_DATA,
  payload,
});

export const isUpdatingModalPlayerObjectiveStatus = (
  payload: ObjectiveRecord[] | null | undefined,
): ActionTypesPlayers => ({
  type: Actions.IS_UPDATING_MODAL_PLAYER_OBJECTIVE_STATUS,
  payload,
});

export const isUpdatingPlayersData = (
  payload: DataPlayerRecord[],
): ActionTypesPlayers => ({
  type: Actions.IS_UPDATING_PLAYERS_DATA,
  payload,
});

export const isUpdatingPlayerData = (
  payload: PlayerData,
): ActionTypesPlayers => ({
  type: Actions.IS_UPDATING_PLAYER_DATA,
  payload,
});

export const toggleUpdatingNote = (payload: boolean): ActionTypesPlayers => ({
  type: Actions.TOGGLE_UPDATING_NOTE,
  payload,
});

export const toggleCreatingNote = (payload: boolean): ActionTypesPlayers => ({
  type: Actions.TOGGLE_CREATING_NOTE,
  payload,
});

export const toggleIsDeletingNote = (payload: boolean): ActionTypesPlayers => ({
  type: Actions.TOGGLE_IS_DELETING_NOTE,
  payload,
});

// list of players per objective
export const getListOfPlayersPerObjective =
  (
    objectiveId: string,
    statusFilter: boolean,
    status: string,
    page: number,
    // itemsPerPage: number,
    limit: number[],
    loadMore: boolean,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const userData = state.auth.userData;
    if (loadMore) dispatch(toggleLoadingMorePlayers(true));
    else {
      dispatch(toggleLoadingPlayers(true));
      dispatch(resetPlayerData());
    }
    return await fetchSafe({
      endpoint: endpoints.zoho.getPlayersObjectives,
      hostTag: userData.Host_ID,
      objectiveID: objectiveId,
      // page: page,
      // itemsPerPage,
      limit: limit.join(','),
      statusFilter,
      status,
    })
      .then(res => {
        const data = <PlayersData>res.data;
        let playersList = null;
        if (data.playerList) {
          playersList = data.playerList.map(player => {
            return {
              PlayerData: {
                ...player.PlayerData,
                VIP_Decile: player.VIP_Decile,
                VIP_Offer: player.VIP_Offer,
                Status_Changed_Time: player.Status_Changed_Time,
                id_status: player.id,
                Preferred_Contact_State: {
                  name: player.PlayerData.Preferred_Contact
                    ? player.PlayerData.Preferred_Contact
                    : '- None -',
                  is_loading: false,
                },
                Snowbird_State: {
                  name: player.PlayerData.Snowbird
                    ? player.PlayerData.Snowbird
                    : null,
                  is_loading: false,
                },
                Objective_Status: {
                  name: player.Objective_Status,
                  is_loading: false,
                },
                Full_Name:
                  player.PlayerData.First_Name + ' ' + player.PlayerData.Name,
              },
              Objective_Status: player.Objective_Status,
              id: player.id,
            };
          });
          if (loadMore) {
            const playersData = getState().players.playersData.playerList;
            dispatch(
              setPlayersData({
                data: {
                  objectiveInformation: data.objectiveInformation,
                  playerList: [...playersData, ...playersList],
                },
                limit: 50,
                page,
                Count: data.Count ? data.Count : 0,
              }),
            );
            dispatch(toggleLoadingMorePlayers(false));
          } else {
            dispatch(
              setPlayersData({
                data: {
                  objectiveInformation: data.objectiveInformation,
                  playerList: playersList,
                },
                limit: 50,
                page,
                Count: data.Count ? data.Count : 0,
              }),
            );
            dispatch(toggleLoadingPlayers(false));
          }
        }
        return { ok: true };
      })
      .catch(error => {
        console.log(error);
        dispatch(toggleLoadingPlayers(false));
      });
  };

// ----- Player Details -----
// get player details for the modal
export const getSpecificPlayerData =
  (Player_ID: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async dispatch => {
    dispatch(toggleLoadingPlayerData(true));

    return await fetchSafe({
      endpoint: endpoints.zoho.getSpecificPlayerData,
      Player_ID,
    })
      .then(async res => {
        // save data with initial states
        const data = <PlayerData>res.data;
        if (data.PlayerObjectiveStatuses) {
          const objectiveList = data.PlayerObjectiveStatuses.map(objective => {
            return { ...objective, is_loading: false };
          });
          data.PlayerObjectiveStatuses = objectiveList;
        }
        data.Associated_Player = {
          is_loading: false,
        };
        data.Snowbird_State = {
          name: data.Snowbird ? data.Snowbird : null,
          is_loading: false,
        };
        data.Preferred_Contact_State = {
          name: data.Preferred_Contact ? data.Preferred_Contact : '- None -',
          is_loading: false,
        };
        dispatch(setPlayerData(data));
        dispatch(toggleLoadingPlayerData(false));
      })
      .catch(error => {
        console.log(error);
      });
  };

// update status and update redux player list state
export const updatePlayerObjectiveStatus =
  (
    Objective_Status: string,
    id_status?: string,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const playerList = state.players.playersData.playerList;
    const playerObjectiveStatuses =
      state.players.playerData.PlayerObjectiveStatuses;
    // console.log(id_status, playerObjectiveStatuses);
    const objectiveIndex = playerObjectiveStatuses?.findIndex(
      el => el.id === id_status,
    );
    if (id_status) {
      // Find index and update is loading
      const rowIndex = playerList.findIndex(player => player.id === id_status);
      playerList[rowIndex].PlayerData.Objective_Status = {
        name: Objective_Status,
        is_loading: true,
      };
      dispatch(isUpdatingPlayersData(playerList));

      // Find index and update status in objective list
      // console.log(objectiveIndex);
      if (
        playerObjectiveStatuses &&
        objectiveIndex !== undefined &&
        objectiveIndex !== -1
      ) {
        // console.log(playerObjectiveStatuses[objectiveIndex], objectiveIndex);
        playerObjectiveStatuses[objectiveIndex].is_loading = true;
        dispatch(isUpdatingModalPlayerObjectiveStatus(playerObjectiveStatuses));
      }

      return await fetchSafe({
        endpoint: endpoints.zoho.updatePlayerObjectiveStatus,
        zohoPlayerObjectiveID: playerList[rowIndex].id,
        Objective_Status,
      })
        .then(async res => {
          const data = <RootStateOrAny>res.data;
          playerList[rowIndex].Objective_Status = Objective_Status;
          playerList[rowIndex].PlayerData.Objective_Status = {
            name: Objective_Status,
            is_loading: false,
          };
          playerList[rowIndex].PlayerData.Status_Changed_Time =
            data.Update_Player_Objective_Statuses.Modified_Time;
          dispatch(isUpdatingPlayersData(playerList));
          // dispatch(isUpdatingPlayersData(playerList));

          if (playerObjectiveStatuses && objectiveIndex !== undefined) {
            playerObjectiveStatuses[objectiveIndex].is_loading = false;
            playerObjectiveStatuses[objectiveIndex].Objective_Status =
              Objective_Status;
            dispatch(
              isUpdatingModalPlayerObjectiveStatus(playerObjectiveStatuses),
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

// update statuses and update redux player data state
export const updatePlayerObjectiveStatusModal =
  (
    Objective_Status: string,
    id_status?: string,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const playerList = state.players.playersData.playerList;
    const playerObjectiveStatuses =
      state.players.playerData.PlayerObjectiveStatuses;

    if (id_status && playerObjectiveStatuses) {
      // Get index of player in list
      const rowIndex = playerList.findIndex(player => player.id === id_status);
      if (rowIndex !== -1) {
        playerList[rowIndex].PlayerData.Objective_Status = {
          name: Objective_Status,
          is_loading: true,
        };
        // Update status of objective
        dispatch(isUpdatingPlayersData(playerList));
      }

      // Find index and update status in objective list
      const objectiveIndex = playerObjectiveStatuses.findIndex(
        el => el.id === id_status,
      );
      playerObjectiveStatuses[objectiveIndex].is_loading = true;
      dispatch(isUpdatingModalPlayerObjectiveStatus(playerObjectiveStatuses));

      return await fetchSafe({
        endpoint: endpoints.zoho.updatePlayerObjectiveStatus,
        zohoPlayerObjectiveID: id_status,
        Objective_Status,
      })
        .then(async res => {
          const data = <RootStateOrAny>res.data;
          // when success update statuses and is_loading
          if (rowIndex !== -1) {
            playerList[rowIndex].Objective_Status = Objective_Status;
            playerList[rowIndex].PlayerData.Objective_Status = {
              name: Objective_Status,
              is_loading: false,
            };
            playerList[rowIndex].PlayerData.Status_Changed_Time =
              data.Update_Player_Objective_Statuses.Modified_Time;
            dispatch(isUpdatingPlayersData(playerList));
            dispatch(isUpdatingPlayersData(playerList));
          }
          playerObjectiveStatuses[objectiveIndex].is_loading = false;
          playerObjectiveStatuses[objectiveIndex].Objective_Status =
            Objective_Status;
          dispatch(
            isUpdatingModalPlayerObjectiveStatus(playerObjectiveStatuses),
          );
        })
        .catch(error => {
          // Return error
          console.log(error);
        });
    }
  };

// update preferred contact and update redux player list state
export const updatePlayerPreferredContact =
  (
    Preferred_Contact: string,
    id_status?: string,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const playerList = state.players.playersData.playerList;
    const playerData = state.players.playerData;

    let Associated_Player = null;
    let Snowbird = null;
    let zohoPlayerID = null;
    let rowIndex = 0;

    // if is called from PlayerObjectives view
    if (id_status) {
      rowIndex = playerList.findIndex(player => player.id === id_status);
      playerList[rowIndex].PlayerData.Preferred_Contact_State = {
        name: Preferred_Contact,
        is_loading: true,
      };
      dispatch(isUpdatingPlayersData(playerList));
      Associated_Player = playerData.associatedPlayerData;
      Snowbird = playerList[rowIndex].PlayerData.Snowbird_State?.name;
      zohoPlayerID = playerList[rowIndex].PlayerData.id;
    } else {
      Associated_Player = playerData.associatedPlayerData;
      Snowbird = playerData.Snowbird;
      zohoPlayerID = playerData.id;

      playerData.Preferred_Contact_State = {
        name: Preferred_Contact,
        is_loading: true,
      };

      dispatch(isUpdatingPlayerData(playerData));
    }
    // console.log(Associated_Player);
    if (Associated_Player) {
      Associated_Player = Associated_Player.Player_Id;
    } else {
      Associated_Player = null;
    }

    return await fetchSafe({
      endpoint: endpoints.zoho.updatePlayerData,
      zohoPlayerID,
      Associated_Player,
      Preferred_Contact,
      Snowbird,
    })
      .then(async () => {
        if (id_status) {
          playerList[rowIndex].PlayerData = {
            ...playerList[rowIndex].PlayerData,
            Preferred_Contact,
            Preferred_Contact_State: {
              name: Preferred_Contact,
              is_loading: false,
            },
          };
          dispatch(isUpdatingPlayersData(playerList));
        }
        // dispatch(
        //   isUpdatingPlayersData(
        //     playerList
        //       .filter(
        //         (player: DataPlayerRecord) =>
        //           player.PlayerData.id === playerList[rowIndex].PlayerData.id,
        //       )
        //       .map((player: DataPlayerRecord) => {
        //         return {
        //           ...player,
        //           PlayerData: {
        //             ...player.PlayerData,
        //             Preferred_Contact,
        //             Preferred_Contact_State: {
        //               name: Preferred_Contact,
        //               is_loading: false,
        //             },
        //           },
        //         };
        //       }),
        //   ),
        // );
        else {
          playerData.Preferred_Contact_State = {
            name: Preferred_Contact,
            is_loading: false,
          };
          playerData.Preferred_Contact = Preferred_Contact;
          dispatch(isUpdatingPlayerData(playerData));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
// update player Snowbird or Tourist
export const updatePlayerSnowBirdType =
  (
    Snowbird_Status: string,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const playerData = state.players.playerData;

    const Snowbird =
      Snowbird_Status === 'Snowbird'
        ? 'S'
        : Snowbird_Status === 'Tourist'
        ? 'T'
        : null;

    playerData.Snowbird_State = {
      name: Snowbird,
      is_loading: true,
    };

    dispatch(isUpdatingPlayerData(playerData));
    let Associated_Player = null;

    const isAssociatedPlayer = playerData.associatedPlayerData;
    if (isAssociatedPlayer) {
      Associated_Player = isAssociatedPlayer.Player_Id;
    } else {
      Associated_Player = null;
    }

    const Preferred_Contact = playerData.Preferred_Contact;

    return await fetchSafe({
      endpoint: endpoints.zoho.updatePlayerData,
      zohoPlayerID: playerData.id,
      Associated_Player,
      Preferred_Contact,
      Snowbird,
    })
      .then(async () => {
        // update loading to false and set name
        playerData.Snowbird_State = {
          name: Snowbird,
          is_loading: false,
        };
        playerData.Snowbird = Snowbird;
        dispatch(isUpdatingPlayerData(playerData));
      })
      .catch(error => {
        console.log(error);
        // update loading to false and set name
        playerData.Snowbird_State = {
          name: Snowbird,
          is_loading: false,
        };
        playerData.Snowbird = Snowbird;
        dispatch(isUpdatingPlayerData(playerData));
      });
  };

// update Associated Player ID
export const updateAssociatedPlayer =
  (
    Associated_Player: string | null,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const playerData = state.players.playerData;
    console.log(playerData);

    if (playerData.Associated_Player) {
      playerData.Associated_Player.is_loading = true;
      dispatch(isUpdatingPlayerData(playerData));
      const zohoPlayerID = playerData.id;
      const Preferred_Contact = playerData.Preferred_Contact;
      const Snowbird = playerData.Snowbird;

      return await fetchSafe({
        endpoint: endpoints.zoho.updatePlayerData,
        zohoPlayerID: zohoPlayerID,
        Associated_Player: Associated_Player,
        Preferred_Contact,
        Snowbird,
      })
        .then(async res => {
          const data = <RootStateOrAny>res.data;
          if (data.code && data.code === 'INVALID_DATA') {
            alert('Invalid Player ID ');
            if (playerData.Associated_Player) {
              playerData.Associated_Player.is_loading = false;
            }
            dispatch(isUpdatingPlayerData(playerData));
          } else {
            if (data.associatedPlayerData) {
              if (playerData.Associated_Player)
                playerData.Associated_Player.is_loading = false;

              playerData.associatedPlayerData = data.associatedPlayerData;
            } else {
              playerData.associatedPlayerData = null;
              if (playerData.Associated_Player)
                playerData.Associated_Player.is_loading = false;
            }
            dispatch(isUpdatingPlayerData(playerData));
          }
        })
        .catch(() => {
          if (playerData.Associated_Player) {
            playerData.Associated_Player.is_loading = false;
            playerData.associatedPlayerData = null;
          }
          dispatch(isUpdatingPlayerData(playerData));
        });
    }
  };

// ----- NOTES -----
// update Note
export const updateNote =
  (
    id: string,
    Name: string,
    Description: string,
  ): ThunkAction<void, PlayersStore, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = <RootStateOrAny>getState();
    const playerData = state.players.playerData;
    const noteIndex = playerData.Notes.findIndex(
      (note: PlayerData) => note.id === id,
    );

    dispatch(toggleUpdatingNote(true));
    return await fetchSafe({
      endpoint: endpoints.zoho.manageNote,
      playerID: playerData.id,
      id,
      type: 'Update',
      Name: Name,
      Description: Description,
    })
      .then(async () => {
        playerData.Notes[noteIndex].Note_Title = Name;
        playerData.Notes[noteIndex].Note_Content = Description;
        dispatch(setPlayerData(playerData));
        dispatch(toggleUpdatingNote(false));
      })
      .catch(error => {
        console.log(error);
        dispatch(toggleUpdatingNote(false));
      });
  };

// delete Note
export const deleteNote =
  (id: string): ThunkAction<void, PlayersStore, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = <RootStateOrAny>getState();
    const playerData = state.players.playerData;

    dispatch(toggleIsDeletingNote(true));
    return await fetchSafe({
      endpoint: endpoints.zoho.manageNote,
      playerID: playerData.id,
      id,
      type: 'Delete',
    })
      .then(async () => {
        playerData.Notes = playerData.Notes.filter(
          (note: PlayerData) => note.id != id,
        );
        dispatch(setPlayerData(playerData));
        dispatch(toggleIsDeletingNote(false));
      })
      .catch(error => {
        console.log(error);
        dispatch(toggleIsDeletingNote(false));
      });
  };

// delete Note
export const createNote =
  (
    Name: string,
    Description: string,
  ): ThunkAction<void, PlayersStore, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = <RootStateOrAny>getState();
    const playerData = state.players.playerData;

    dispatch(toggleCreatingNote(true));
    return await fetchSafe({
      endpoint: endpoints.zoho.manageNote,
      playerID: playerData.id,
      type: 'New',
      Name,
      Description,
    })
      .then(async res => {
        const data = <NoteRecord>res.data;
        const newNote = {
          Note_Title: Name,
          Note_Content: Description,
          Modified_Time: data.Modified_Time,
          Created_Time: data.Created_Time,
          Created_By: data.Created_By,
          id: data.id,
        };
        if (playerData.Notes) playerData.Notes.unshift(newNote);
        else playerData.Notes = [{ ...newNote }];
        dispatch(setPlayerData(playerData));
        dispatch(toggleCreatingNote(false));
      })
      .catch(error => {
        console.log(error);
        dispatch(toggleCreatingNote(false));
      });
  };
