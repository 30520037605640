import { Reducer } from 'redux';
import * as ActionType from '../actions';
import { PLAYERS_INITIAL_STATE } from '../../constants/general';
import { PlayersStore } from '../interfaces';
import { ActionTypesPlayers } from 'state/action-types/playerObjectives';

const PlayersReducer: Reducer<PlayersStore, ActionTypesPlayers> = (
  state = PLAYERS_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ActionType.SET_PLAYERS_DATA:
      return {
        ...state,
        playersData: {
          ...action.payload.data,
          Count: action.payload.Count,
          page: action.payload.page,
        },
      };
    case ActionType.RESET_PLAYERS_DATA:
      return {
        ...state,
        playersData: {
          objectiveInformation: {
            Name: '',
            Objective_Type: '',
            Created_Time: '',
          },
          playerList: [],
          page: 1,
          Count: 0,
        },
      };
    case ActionType.SET_PLAYER_DATA:
      return {
        ...state,
        playerData: action.payload,
      };
    case ActionType.TOGGLE_LOADING_PLAYERS:
      return { ...state, isLoadingPlayers: action.payload };
    case ActionType.TOGGLE_LOADING_MORE_PLAYERS:
      return {
        ...state,
        playersData: { ...state.playersData, isLoadingMore: action.payload },
      };
    case ActionType.TOGGLE_LOADING_PLAYER_DATA:
      return { ...state, isLoadingPlayerData: action.payload };
    case ActionType.IS_UPDATING_MODAL_PLAYER_OBJECTIVE_STATUS:
      return {
        ...state,
        playerData: { ...state.playerData, playerList: action.payload },
      };
    case ActionType.IS_UPDATING_PLAYERS_DATA:
      return {
        ...state,
        playersData: { ...state.playersData, playerList: action.payload },
      };
    case ActionType.IS_UPDATING_PLAYER_DATA:
      return {
        ...state,
        playerData: action.payload,
      };
    case ActionType.TOGGLE_UPDATING_NOTE:
      return { ...state, isUpdatingNote: action.payload };
    case ActionType.TOGGLE_CREATING_NOTE:
      return { ...state, isCreatingNote: action.payload };
    case ActionType.TOGGLE_IS_DELETING_NOTE:
      return { ...state, isDeletingNote: action.payload };
    case ActionType.TOGGLE_UPDATING_PLAYER_OBJECTIVE_STATUS:
      return { ...state, isLoadingPlayerDetails: action.payload };
    default:
      return state;
  }
};

export default PlayersReducer;
