import { Status } from 'constants/general';
import { StatusTypes } from 'interfaces';
import React from 'react';
import styles from './Card.module.scss';

interface CardProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  status?: StatusTypes;
  full?: boolean;
  fill?: boolean;
  container?: boolean;
  outerTitle?: boolean;
}

const Card = ({
  title,
  children,
  status,
  container,
  fill,
  outerTitle,
}: CardProps) => {
  return (
    <div
      className={`${styles.cardContainer} ${
        container ? styles.container : ''
      }`}>
      {outerTitle && title && (
        <h5
          className={`${
            status === Status.PRIMARY ? 'text-blue-500' : 'text-black'
          } text-lg leading-tight font-bold mb-2`}>
          {title}
        </h5>
      )}
      <div className={`${styles.card}  ${fill ? styles.fill : ''}`}>
        {!outerTitle && title && (
          <h5
            className={`${
              status === Status.PRIMARY ? 'text-blue-500' : 'text-black'
            } text-xl font-semibold mb-4`}>
            {title}
          </h5>
        )}
        {children}
      </div>
    </div>
  );
};

export default Card;
