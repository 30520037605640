import Card from 'components/common/Card/Card';
import React from 'react';
import logo from 'assets/vcclogo.png';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import TextInput from 'components/common/TextInput/TextInput';
import ButtonCustom from 'components/common/Button/Button';
import { SaveIcon, ShieldCheckIcon } from '@heroicons/react/outline';

const ProfileView = () => {
  const authState = useSelector((state: RootState) => state.auth);
  return (
    <Card container fill title="Host Profile">
      <div className="flex flex-col justify-center items-center col-span-3 lg:col-span-1">
        <img
          src={logo}
          className="rounded-full w-28 h-28 object-contain"
          alt="Avatar"
        />
        <div className=" flex flex-col justify-center items-center text-lg">
          <span className="font-semibold">{authState.userData.Name}</span>
          <div className="text-gray-500 flex gap-1 select-none leading-none">
            <ShieldCheckIcon className="w-4 h-4 text-green-600" />
            <div>Host</div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-1 mt-6">
        <div className="col-span-0 lg:col-span-1 "></div>
        <div className="grid gap-3 col-span-4 lg:col-span-2 caret-transparent">
          <p className="font-semibold">Account details</p>
          <TextInput
            size="small"
            label="Zoho ID"
            placeholder={'Zoho ID'}
            value={authState.userData.id}
            onChange={() => void {}}
            disabled
          />
          <TextInput
            size="small"
            label="Google UID"
            placeholder="Enter Google ID"
            value={authState.userData.Google_Auth_UID}
            onChange={() => void {}}
            disabled
          />
          <hr />
          <p className="font-semibold">Host details</p>
          <TextInput
            size="small"
            label="Host ID"
            placeholder="Enter host ID"
            value={authState.userData.Host_ID}
            onChange={() => void {}}
            disabled
          />
          <TextInput
            size="small"
            label="Email"
            placeholder="Enter your Email"
            value={authState.userData.Email}
            onChange={() => void {}}
            disabled
          />
          <TextInput
            size="small"
            label="Full Name"
            placeholder="Enter your full name"
            value={authState.userData.Name}
            onChange={() => void {}}
            disabled
          />
          <TextInput
            size="small"
            label="Password"
            placeholder="Enter your password"
            value={'**********'}
            onChange={() => void {}}
            disabled
          />
          <ButtonCustom
            disabled={true}
            title="Save changes"
            size="small"
            status="primary"
            iconLeft={<SaveIcon className="h-4 w-4" />}
          />
        </div>
      </div>
    </Card>
  );
};

export default ProfileView;
