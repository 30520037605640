import React from 'react';
import styles from './Tabs.module.scss';

interface TabLink extends React.HtmlHTMLAttributes<HTMLAnchorElement> {
  id: string;
  title: string;
  active?: boolean;
  type: string;
}

const TabLink = ({ id, title, active, type, ...others }: TabLink) => {
  return (
    <li className="nav-item" role="presentation">
      <a
        href={`#tabs-${id}`}
        className={`nav-link ${styles.tabLink} ${
          type === 'pills' ? 'rounded' : ''
        } ${active ? 'active' : ''}`}
        id={`tabs-${id}-tab`}
        data-bs-toggle="pill"
        data-bs-target="#tabs-home"
        role="tab"
        aria-controls={`tabs-${id}`}
        aria-selected={active ? 'true' : 'false'}
        {...others}>
        {title}
      </a>
    </li>
  );
};

export default TabLink;
