import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import Card from '../../components/common/Card/Card';
import styles from './MyPerformance.module.scss';

const MyPerformanceView = () => {
  const userState = useSelector((state: RootState) => state.auth).userData;

  return (
    <Card container fill title="My Performance">
      <iframe
        className={styles.myPerformance}
        frameBorder={0}
        src={`https://analytics.zoho.com/open-view/2333032000001999234?ZOHO_CRITERIA=%22Zoho%20Performance%20Query%20Table%22.%22Host%20ID%22%20%3D%20'${userState.Host_ID}'`}></iframe>
    </Card>
  );
};

export default MyPerformanceView;
