import React, { useRef } from 'react';
import SideNav from '../../sidenav/SideNav';
import { Outlet } from 'react-router-dom';
import Header from '../../Header/Header';
import LayoutContent from './LayoutContent';
import ScrollToTop from '../ScrollTop/ScrollToTop';
const LayoutDashboard = () => {
  const notesRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex bg-gray-100 overflow-x-auto">
      <div className="flex-shrink">
        <SideNav />
      </div>
      <div ref={notesRef} className="flex-1 max-h-screen overflow-y-auto">
        <LayoutContent head={<Header />} content={<Outlet />} />
        <ScrollToTop notesRef={notesRef} />
      </div>
    </div>
  );
};

export default LayoutDashboard;
