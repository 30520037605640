import { SearchIcon } from '@heroicons/react/outline';
import React from 'react';
import { Row, useAsyncDebounce } from 'react-table';
import TextInput from '../TextInput/TextInput';

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: Array<Row>;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <TextInput
      rounded
      type="text"
      size="xsmall"
      placeholder={`Search in ${count} records...`}
      value={value || ''}
      icon={<SearchIcon className="w-4 h-4 absolute left-2 text-gray-500" />}
      onChange={e => {
        setValue(e.currentTarget.value);
        onChange(e.currentTarget.value);
      }}
    />
  );
};

export default GlobalFilter;
