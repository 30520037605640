export const handleFormatDate = (date: string) => {
  const formattedDate = date.split('-');
  return formattedDate[1] + '/' + formattedDate[2] + '/' + formattedDate[0];
};

export const handleFormatDateTime = (date: string) => {
  const formattedDate = new Date(date);
  return (
    formattedDate.toLocaleDateString() +
    ' at ' +
    formattedDate.toLocaleTimeString()
  );
};

const formatMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const handleFormatMoney = (number?: number) => {
  if (number) {
    return formatMoney.format(number);
  } else {
    return formatMoney.format(0);
  }
};

export const capitalizeWords = (arr: string[]) => {
  return arr
    .map(element => {
      return (
        element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
      );
    })
    .join(' ');
};

export const formatPhoneNumber = (phoneNumber: string) => {
  // normalize and remove unnecesarry characters
  phoneNumber = phoneNumber.replace(/[^\d]/g, '');

  return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
};
