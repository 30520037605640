import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionTypesDashboard } from '../action-types/dashboard';
import * as Actions from '../actions';
import { DataObjectives, DataEvents, DashboardStore } from '../interfaces';
import { fetchSafe } from 'helpers/firebaseSetup';
import endpoints from 'constants/endpoints';

// Action creators
export const setEventsData = (payload: DataEvents[]): ActionTypesDashboard => ({
  type: Actions.SET_EVENTS_DATA,
  payload,
});

export const setObjectivesData = (
  payload: DataObjectives[],
): ActionTypesDashboard => ({
  type: Actions.SET_OBJECTIVES_DATA,
  payload,
});

export const toggleLoadingDashboard = (
  payload: boolean,
): ActionTypesDashboard => ({
  type: Actions.TOGGLE_LOADING_DASHBOARD,
  payload,
});

export const toggleLoadingEvents = (
  payload: boolean,
): ActionTypesDashboard => ({
  type: Actions.TOGGLE_LOADING_EVENTS,
  payload,
});

export const toggleLoadingObjectives = (
  payload: boolean,
): ActionTypesDashboard => ({
  type: Actions.TOGGLE_LOADING_OBJECTIVES,
  payload,
});

// List of objectives
export const getListOfObjectives =
  (): ThunkAction<void, DashboardStore, unknown, Action<string>> =>
  async dispatch => {
    // const state = getState();
    // const userData = JSON.parse(JSON.stringify(state)).auth.userData;
    dispatch(toggleLoadingObjectives(true));
    return await fetchSafe({
      endpoint: endpoints.zoho.getAssignedObjectives,
      // Host_ID: userData.Host_ID,
    })
      .then(res => {
        const data = JSON.parse(JSON.stringify(res)).data;
        if (Array.isArray(data)) {
          dispatch(setObjectivesData(data));
        } else {
          dispatch(setObjectivesData([]));
        }
        dispatch(toggleLoadingObjectives(false));
      })
      .catch(error => {
        console.log(error);
      });
  };

//  Get List of events
export const getListOfOEvents =
  (): ThunkAction<void, DashboardStore, unknown, Action<string>> =>
  async dispatch => {
    dispatch(toggleLoadingEvents(true));
    return await fetchSafe({
      endpoint: endpoints.zoho.getIncomingEvents,
    })
      .then(res => {
        const data = JSON.parse(JSON.stringify(res)).data;
        if (Array.isArray(data)) {
          const activeEvents = data.filter((event: DataEvents) => {
            const date = new Date(event.Start_Date.replace(/-/g, '/'));
            const eventDate = date.getDate();
            const currentDate = new Date().getDate();
            const eventMonth = date.getMonth();
            const currentMonth = new Date().getMonth();
            return eventDate === currentDate && eventMonth === currentMonth;
          });

          const incomingEvents = data.filter((event: DataEvents) => {
            const date = new Date(event.Start_Date.replace(/-/g, '/'));
            const eventDate = date.getDate();
            const currentDate = new Date().getDate();
            const eventMonth = date.getMonth();
            const currentMonth = new Date().getMonth();
            if (eventDate === currentDate && eventMonth > currentMonth)
              return true;
            else if (eventDate !== currentDate && eventMonth >= currentMonth)
              return true;
            else return false;
          });

          const pastEvents = data.filter((event: DataEvents) => {
            const date = new Date(event.Start_Date.replace(/-/g, '/'));
            const eventMonth = date.getMonth();
            const currentMonth = new Date().getMonth();
            return eventMonth < currentMonth;
          });

          activeEvents.sort((a: DataEvents, b: DataEvents) => {
            return Date.parse(a.Start_Date) - Date.parse(b.Start_Date);
          });
          incomingEvents.sort((a: DataEvents, b: DataEvents) => {
            return Date.parse(a.Start_Date) - Date.parse(b.Start_Date);
          });
          pastEvents.sort((a: DataEvents, b: DataEvents) => {
            return Date.parse(a.Start_Date) - Date.parse(b.Start_Date);
          });
          const newEvents = [...activeEvents, ...incomingEvents, ...pastEvents];
          dispatch(setEventsData(newEvents));
        } else {
          dispatch(setEventsData([]));
        }
        dispatch(toggleLoadingEvents(false));
      })
      .catch(error => {
        console.log(error);
      });
  };
