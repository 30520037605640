import React from 'react';
import styles from './TextInput.module.scss';
import { Size } from '../../../constants/general';

interface TextInput extends React.HTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  label?: string;
  placeholder: string;
  type?: string;
  value?: string;
  floating?: boolean;
  size?: string;
  rounded?: boolean;
  icon?: JSX.Element;
  required?: boolean;
  disabled?: boolean;
  uncontrolled?: boolean;
  name?: string;
  autoFocus?: boolean;
}

const TextInput = ({
  label,
  type,
  value,
  floating,
  size,
  rounded,
  icon,
  disabled,
  uncontrolled,
  autoFocus,
  ...others
}: TextInput) => {
  const id = others.placeholder.split(' ').join('').replace('-', '');
  return (
    <div className="">
      {label && (
        <div className="mb-1 text-left font-normal text-sm">{label}</div>
      )}
      <div className={`${floating ? 'form-floating' : ''} xl:w-full`}>
        <div className="relative flex items-center">
          {icon}
          {uncontrolled ? (
            <input
              autoFocus={autoFocus}
              disabled={disabled}
              type={type ? type : 'text'}
              className={`form-control ${styles.textInput} ${
                size === Size.SMALL
                  ? styles.textInputSm
                  : size === Size.XS
                  ? styles.textInputXsm
                  : styles.textInputMd
              } ${rounded ? '!rounded-2xl' : ''} ${icon ? '!pl-7' : ''} ${
                disabled ? '!text-gray-400' : ''
              }`}
              id={id}
              {...others}
            />
          ) : (
            <input
              autoFocus={autoFocus}
              disabled={disabled}
              value={value ? value : ''}
              type={type ? type : 'text'}
              className={`form-control ${styles.textInput} ${
                size === Size.SMALL
                  ? styles.textInputSm
                  : size === Size.XS
                  ? styles.textInputXsm
                  : styles.textInputMd
              } ${rounded ? '!rounded-2xl' : ''} ${icon ? '!pl-7' : ''} ${
                disabled ? '!text-gray-400' : ''
              }`}
              id={id}
              {...others}
            />
          )}
        </div>
        {floating && (
          <label
            htmlFor={id}
            className={`form-label ${
              size === 'small' ? styles.labelInputSm : styles.labelInputMd
            } `}>
            {others.placeholder}
          </label>
        )}
      </div>
    </div>
  );
};

export default TextInput;
