export const calcUpDown = (
  Current_Cycle_Reward: number | null,
  Prior_Cycle_Reward: number | null,
) => {
  let priorMonthRewardMinus8 = 0;
  let currentMonthRewardMinus8 = 0;
  let upDown = <string | number>0;
  let upDownNumber = 0;
  let upDownText = <string | number>'';
  let sign = '';

  // Calc priorMonthRewardMinus8
  if (Prior_Cycle_Reward)
    if (Prior_Cycle_Reward > 8) priorMonthRewardMinus8 = Prior_Cycle_Reward - 8;
    else priorMonthRewardMinus8 = Prior_Cycle_Reward;
  // Calc currentMonthRewardMinus8
  if (Current_Cycle_Reward)
    if (Current_Cycle_Reward > 8)
      currentMonthRewardMinus8 = Current_Cycle_Reward - 8;
    else currentMonthRewardMinus8 = Current_Cycle_Reward;
  // Get upDown value
  if (
    priorMonthRewardMinus8 >= 0 &&
    priorMonthRewardMinus8 === currentMonthRewardMinus8
  ) {
    upDown = 'Flat';
  } else if (priorMonthRewardMinus8 >= 0 && currentMonthRewardMinus8 === null) {
    upDown = 'Lapsed';
  } else if (priorMonthRewardMinus8 === null && currentMonthRewardMinus8 >= 0) {
    upDown = 'New';
  } else if (priorMonthRewardMinus8 === 0 && currentMonthRewardMinus8 > 0) {
    upDown = priorMonthRewardMinus8 - currentMonthRewardMinus8 + 9;
  } else if (priorMonthRewardMinus8 > 0 && currentMonthRewardMinus8 === 0) {
    upDown = priorMonthRewardMinus8 - 9;
  } else if (priorMonthRewardMinus8 > 0 && currentMonthRewardMinus8 > 0) {
    upDown = priorMonthRewardMinus8 - currentMonthRewardMinus8;
  } else {
    upDown = 0;
  }
  // Get upDown number
  if (priorMonthRewardMinus8 === 0 && currentMonthRewardMinus8 > 0) {
    upDownNumber = priorMonthRewardMinus8 - currentMonthRewardMinus8 + 9;
  } else if (priorMonthRewardMinus8 > 0 && currentMonthRewardMinus8 === 0) {
    upDownNumber = priorMonthRewardMinus8 - 9;
  } else if (priorMonthRewardMinus8 > 0 && currentMonthRewardMinus8 > 0) {
    upDownNumber = priorMonthRewardMinus8 - currentMonthRewardMinus8;
  } else {
    upDownNumber = 0;
  }
  // Get upDown text
  if (upDownNumber < 0) {
    upDownText = 'Down ' + Math.abs(upDownNumber);
  } else if (upDownNumber > 0) {
    upDownText = 'Up ' + upDownNumber;
  } else if (!Prior_Cycle_Reward && !Current_Cycle_Reward) {
    upDownText = 'No rewards';
  } else {
    upDownText = upDown;
  }

  // Get upDown sign
  sign = upDownNumber < 0 ? '-' : upDownNumber != 0 ? '+' : '';
  return `${sign}${upDownNumber} (${upDownText})`;
};
