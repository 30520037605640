import React, { useEffect } from 'react';
import {
  CalendarIcon,
  ChartSquareBarIcon,
  ClipboardListIcon,
  EyeIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import ButtonCustom from '../../components/common/Button/Button';
import Card from '../../components/common/Card/Card';
import List from '../../components/common/List/List';
import ListItem from '../../components/common/List/ListItem';
import Slider from '../../components/common/Slider/Slider';
import styles from './DashboardView.module.scss';
import EventItem from './EventItem';
import { bindActionCreators } from 'redux';
import { actionCreatorsDashboard } from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import ObjectiveSkeleton from './skeleton/ObjectiveSkeleton';
import EventSkeleton from './skeleton/EventSkeleton';
import Button from '../../components/common/Button/Button';
import IndeterminateProgressBar from 'components/common/IndeterminateProgressBar/IndeterminateProgressBar';

const DashboardView = () => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state: RootState) => state.dashboard);

  const { getListOfObjectives, getListOfOEvents } = bindActionCreators(
    actionCreatorsDashboard,
    dispatch,
  );

  const navigate = useNavigate();
  const handleGetObjective = (id: string) => {
    navigate(`/objective/${id}`);
  };

  useEffect(() => {
    const handleGetObjectives = async () => {
      await getListOfObjectives();
    };

    const handleGetEvents = async () => {
      await getListOfOEvents();
    };
    handleGetObjectives();
    handleGetEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IndeterminateProgressBar
        show={
          dashboardState.isLoadingObjectives || dashboardState.isLoadingEvents
        }
      />
      <div className={styles.dashboard}>
        <div className={`${styles.carouselItem} select-none`}>
          <Slider />
        </div>
        <div className={`${styles.optionsItem}`}>
          <Card outerTitle title="Tools">
            <div className="flex items-center gap-5">
              <Link to={'/search'}>
                <Button
                  status="primary"
                  outlined
                  iconLeft={<SearchIcon className="w-5 h-5" />}
                  size="small"
                  title="Search player"
                />
              </Link>
              <Link to={'/my-performance'}>
                <Button
                  status="primary"
                  outlined
                  iconLeft={<ChartSquareBarIcon className="w-5 h-5" />}
                  size="small"
                  title="My performance"
                />
              </Link>
            </div>
          </Card>
        </div>
        <div className={`${styles.listOfObjectivesItem}`}>
          <Card outerTitle title="List Of Objectives">
            {dashboardState.isLoadingObjectives ? (
              <ObjectiveSkeleton items={2} />
            ) : (
              <List>
                {dashboardState.objectives.length > 0 ? (
                  dashboardState.objectives.map((objective, key) => (
                    <ListItem
                      unique={dashboardState.objectives.length === key + 1}
                      justify
                      key={`objective_${key}`}
                      cols={'grid-cols-5'}>
                      <div className="col-span-4 sm:col-span-3 lg:col-span-1 min-w-max">
                        <p className="font-normal text-sm text-gray-500">
                          Start Date
                        </p>
                        {objective.Start_Date}
                      </div>
                      <div className="col-span-4 sm:col-span-2 lg:col-span-1 min-w-max">
                        <p className="font-normal text-sm text-gray-500">
                          End Date
                        </p>
                        {objective.End_Date}
                      </div>
                      <div className="col-span-4 sm:col-span-3 lg:col-span-2 min-w-max text-blue-500 font-semibold">
                        <p className="font-normal text-sm text-gray-500">
                          Name
                        </p>
                        {objective.Name}
                      </div>
                      <div className="col-span-2 sm:col-span-1 lg:col-span-1 flex lg:justify-center items-center">
                        <div>
                          <ButtonCustom
                            rounded
                            iconLeft={<EyeIcon className="w-4 h-4" />}
                            size="small"
                            title="View"
                            status="primary"
                            outlined
                            onClick={() => handleGetObjective(objective.id)}
                          />
                        </div>
                      </div>
                    </ListItem>
                  ))
                ) : (
                  <div className="flex flex-col items-center mt-5">
                    <ClipboardListIcon className="w-8 h-8 text-gray-400" />
                    <p className="text-gray-500 text-base">
                      No host objectives found
                    </p>
                  </div>
                )}
              </List>
            )}
          </Card>
        </div>

        <div className={`${styles.eventsItem} ${styles.eventsContainer} `}>
          <Card fill outerTitle title="Events Calendar">
            <div className="flex gap-3 items-center">
              <div className="flex gap-1 items-center ">
                <div className="h-3 w-3 bg-green-200 rounded-full"></div>
                <span>Happening</span>
              </div>
              <div className="flex gap-1 items-center ">
                <div className="h-3 w-3 bg-blue-200 rounded-full"></div>
                <span>Upcoming</span>
              </div>
            </div>
            <List>
              {dashboardState.isLoadingEvents ? (
                <EventSkeleton items={4} />
              ) : dashboardState.events.length > 0 ? (
                dashboardState.events.map((event, key) => (
                  <ListItem
                    justify
                    key={`objective_${key}`}
                    cols={'grid-cols-1 !py-3'}
                    unique={dashboardState.events.length === key + 1}>
                    <EventItem event={event} />
                  </ListItem>
                ))
              ) : (
                <div className="flex flex-col items-center mt-5">
                  <CalendarIcon className="w-8 h-8 text-gray-400" />
                  <p className="text-gray-500 text-base">No events found</p>
                </div>
              )}
            </List>
          </Card>
        </div>
      </div>
    </>
  );
};

export default DashboardView;
