import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionTypes } from '../action-types';
import * as Actions from '../actions';
import { Data, AuthStore } from '../interfaces';
import {
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signOut,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  RecaptchaVerifier,
  getMultiFactorResolver,
  MultiFactorResolver,
} from 'firebase/auth';

import endpoints from 'constants/endpoints';
import { fetchSafe } from 'helpers/firebaseSetup';
import { RootStateOrAny } from 'react-redux';

// Action creators
export const setUserData = (payload: Data): ActionTypes => ({
  type: Actions.SET_USER_DATA,
  payload,
});

export const toggleLoadingLogin = (payload: boolean): ActionTypes => ({
  type: Actions.TOGGLE_LOADING_LOGIN,
  payload,
});

export const toggleLoadingResetPass = (payload: boolean): ActionTypes => ({
  type: Actions.TOGGLE_LOADING_RESET_PASS,
  payload,
});

export const toggleLoadingLogout = (payload: boolean): ActionTypes => ({
  type: Actions.TOGGLE_LOADING_LOGOUT,
  payload,
});

export const setLoginData = (payload: {
  property: string;
  value: string | boolean | MultiFactorResolver;
}): ActionTypes => ({
  type: Actions.SET_LOGIN_DATA,
  payload,
});

// Login thunk
export const userLogin =
  (
    email: string,
    password: string,
    recaptchaVerifier: RecaptchaVerifier | null,
  ): ThunkAction<void, AuthStore, unknown, Action<string>> =>
  async dispatch => {
    const auth = getAuth();
    dispatch(toggleLoadingLogin(true));
    if (recaptchaVerifier)
      signInWithEmailAndPassword(auth, email, password)
        .then(async user => {
          // User successfully signed in with email and password.
          await dispatch(getSpecificHostData(user.user.uid));
        })
        .catch(async error => {
          // Login has an error and user need to verify password, email or needs MFA
          if (error.code === 'auth/multi-factor-auth-required') {
            // Get resolver from error to proceed with login flow
            const resolver = getMultiFactorResolver(auth, error);
            // multiFactor type
            if (
              resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID
            ) {
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              };
              //initialize phone authprovider
              const phoneAuthProvider = new PhoneAuthProvider(getAuth());
              // Send SMS verification code
              const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                recaptchaVerifier,
              );
              dispatch(
                setLoginData({
                  property: 'verificationId',
                  value: verificationId,
                }),
              );
              dispatch(
                setLoginData({
                  property: 'resolver',
                  value: resolver,
                }),
              );
              dispatch(setLoginData({ property: 'MFA', value: true }));
              dispatch(
                setLoginData({
                  property: 'errorMessage',
                  value: '',
                }),
              );
            } else {
              dispatch(
                setLoginData({
                  property: 'errorMessage',
                  value: 'Auth method not valid.',
                }),
              );
            }
          } else if (error.code === 'auth/wrong-password') {
            dispatch(
              setLoginData({
                property: 'errorMessage',
                value: 'Wrong password.',
              }),
            );
          } else if (error.code === 'auth/user-not-found') {
            dispatch(
              setLoginData({
                property: 'errorMessage',
                value: 'No user found with this e-mail.',
              }),
            );
          } else {
            dispatch(
              setLoginData({
                property: 'errorMessage',
                value: 'Something went wrong. Try again later.',
              }),
            );
          }
          dispatch(toggleLoadingLogin(false));
        });
    else {
      dispatch(toggleLoadingLogin(false));
      dispatch(
        setLoginData({
          property: 'errorMessage',
          value: 'Recaptcha not verified',
        }),
      );
    }
  };
// Verify security code afterlogin with email and password
export const verifySecurityCode =
  (): ThunkAction<void, AuthStore, unknown, Action<string>> =>
  async (dispatch, getState) => {
    // Get resolver ID and Code from redux
    const state = <RootStateOrAny>getState();
    const authState = state.auth;
    const resolver = <MultiFactorResolver>authState.loginInfo.resolver;
    // create credentials with the session id and the user codo
    const authCredential = PhoneAuthProvider.credential(
      authState.loginInfo.verificationId,
      authState.loginInfo.verificationCode,
    );
    // Confirm ownership of phone
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(authCredential);

    dispatch(toggleLoadingLogin(true));
    // Complete sign-in with the resolver.
    resolver
      .resolveSignIn(multiFactorAssertion)
      .then(async user => {
        // User successfully signed in with the second factor phone number.
        await dispatch(getSpecificHostData(user.user.uid));
      })
      .catch(error => {
        // Something is wrong with the code or another exception
        if (error.code === 'auth/invalid-verification-code') {
          dispatch(
            setLoginData({
              property: 'errorMessage',
              value: 'Invalid Code. Please try again.',
            }),
          );
        } else if (error.code === 'auth/missing-code') {
          dispatch(
            setLoginData({
              property: 'errorMessage',
              value: `We sent you a 6 digit code to ${
                JSON.parse(JSON.stringify(resolver.hints[0])).phoneNumber
              } Please enter the code to log in.`,
            }),
          );
        } else if (error.code === 'auth/code-expired') {
          dispatch(
            setLoginData({
              property: 'errorMessage',
              value: 'Invalid or expired code. Please try again.',
            }),
          );
          dispatch(userLogout());
          window.location.href = '/';
        } else {
          dispatch(
            setLoginData({
              property: 'errorMessage',
              value: 'Something went wrong. Please try again.',
            }),
          );
        }
        dispatch(toggleLoadingLogin(false));
      });
  };

// Logout
export const userLogout =
  (): ThunkAction<void, AuthStore, unknown, Action<string>> =>
  async dispatch => {
    dispatch(toggleLoadingLogout(true));
    await signOut(getAuth());
    localStorage.removeItem('auth');
    window.location.href = '/';
  };

// Reset password
export const resetPassword =
  (email: string): ThunkAction<void, AuthStore, unknown, Action<string>> =>
  async dispatch => {
    dispatch(toggleLoadingResetPass(true));
    const actionCodeSettings = {
      url: <string>process.env.REACT_APP_REDIRECT_LOGIN,
    };
    return sendPasswordResetEmail(getAuth(), email, actionCodeSettings)
      .then(function () {
        dispatch(toggleLoadingResetPass(false));
        return { ok: true, message: 'Email sent' };
      })
      .catch(error => {
        console.log(error);
        dispatch(toggleLoadingResetPass(false));
        if (error.code === 'auth/invalid-email')
          return { ok: false, message: 'Wrong e-mail' };
        else if (error.code === 'auth/user-not-found')
          return { ok: false, message: 'No user found with this e-mail' };
        else if (error.code === 'auth/invalid-continue-uri')
          return { ok: false, message: 'Missing domain in continue url' };
        else
          return {
            ok: false,
            message: 'Something went wrong. Try again later.',
          };
      });
  };

// Get host data after successfully verify the security code
export const getSpecificHostData =
  (uid: string): ThunkAction<void, AuthStore, unknown, Action<string>> =>
  async dispatch => {
    return await fetchSafe({
      endpoint: endpoints.zoho.getSpecificHostData,
      googleUID: uid,
    })
      .then(res => {
        const user = JSON.parse(JSON.stringify(res)).data;
        const userData = {
          id: user.id,
          Google_Auth_UID: user.Google_Auth_UID,
          Email: user.Email,
          Phone: user.Phone,
          Name: user.Name,
          Host_ID: user.Host_ID,
        };
        localStorage.setItem('auth', JSON.stringify(userData));
        dispatch(setUserData(userData));
        window.location.href = '/';
      })
      .catch(error => {
        console.log(`error: ${JSON.stringify(error)}`);
      });
  };
