import React, { useState } from 'react';
import logo from '../../assets/vcclogo.png';
import TextInput from '../../components/common/TextInput/TextInput';
import ButtonCustom from '../../components/common/Button/Button';
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreatorsAuth } from 'state';
import { RootState } from 'state/store';
import IndeterminateProgressBar from 'components/common/IndeterminateProgressBar/IndeterminateProgressBar';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
    errors: { message: '' },
    sent: false,
  });
  const state = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const { resetPassword } = bindActionCreators(actionCreatorsAuth, dispatch);

  const handleChangeEmail = ({
    currentTarget,
  }: React.FormEvent<HTMLInputElement>) => {
    setFormData(prevState => ({ ...prevState, email: currentTarget.value }));
  };
  const handleSendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = JSON.parse(
      JSON.stringify(await resetPassword(formData.email)),
    );
    if (!result.ok) {
      setFormData(prevState => ({
        ...prevState,
        sent: false,
        errors: { message: result.message },
      }));
    } else {
      setFormData(prevState => ({ ...prevState, sent: true }));
    }
  };

  return (
    <>
      <IndeterminateProgressBar show={state.isLoadingResetPass} />
      <div className="flex flex-col justify-center items-center h-screen  bg-gray-50">
        <div className="flex w-full max-w-sm text-gray-600 text-sm">
          <a
            href="/auth/login"
            className="flex items-center gap-1 cursor-pointer">
            <ArrowLeftIcon className="w-4 h-4" /> <p>Return to login</p>
          </a>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-10 w-full max-w-sm">
          <div className="flex justify-center">
            <img src={logo} className="h-24" alt="logo" />
          </div>
          <p className="text-blue-500 font-semibold text-lg text-center">
            Forgot Password
          </p>

          <p className="text-black  text-sm text-justify mt-3">
            Please enter your Email to verify your account.
          </p>
          {formData.sent ? (
            <div
              className="flex items-center gap-1 justify-center rounded-md bg-green-100 py-2 px-2 text-sm text-center text-green-700 my-2"
              role="alert">
              <CheckCircleIcon className="w-4 h-4" />
              <div className="mt-1">We sent you a password reset email</div>
            </div>
          ) : (
            formData.errors.message && (
              <div
                className="flex items-center gap-1 justify-center bg-red-100 py-2 px-2 rounded-md text-sm text-center text-red-700 my-2"
                role="alert">
                <XCircleIcon className="w-4 h-4" />
                <div className="mt-1">{formData.errors.message}</div>
              </div>
            )
          )}
          <form onSubmit={handleSendEmail}>
            <div className="my-2">
              <TextInput
                value={formData.email}
                onChange={handleChangeEmail}
                size="small"
                label="E-mail"
                type="email"
                placeholder="Enter your e-mail"
              />
            </div>
            <div className="flex flex-col justify-center mt-6">
              <ButtonCustom
                loading={state.isLoadingResetPass}
                loadingText={'Sending email...'}
                disabled={
                  formData.email.trim().length === 0 || state.isLoadingResetPass
                }
                type="submit"
                size="small"
                status="primary"
                title="Send Email"
              />
              <a href="/auth" className="mt-2 text-center text-sm">
                Already have an account? Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
