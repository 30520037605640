const endpoints = {
  firebase: {
    postGeneric: 'https://us-central1-vcc-zoho.cloudfunctions.net/safePostCall',
  },
  zoho: {
    getSpecificHostData:
      'https://www.zohoapis.com/crm/v2/functions/hostportalspecifichostdata/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
    getAssignedObjectives:
      'https://www.zohoapis.com/crm/v2/functions/getassignedobjectives/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
    getIncomingEvents:
      'https://www.zohoapis.com/crm/v2/functions/hostportaleventcalendar/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
    getPlayersObjectives:
      'https://www.zohoapis.com/crm/v2/functions/objective_player_lists/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
    getSpecificPlayerData:
      'https://www.zohoapis.com/crm/v2/functions/hostportalspecificdataplayer/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
    updatePlayerObjectiveStatus:
      'https://www.zohoapis.com/crm/v2/functions/hostportalupdateobjectivestatus/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
    updatePlayerData:
      'https://www.zohoapis.com/crm/v2/functions/hostportalupdatedataplayer/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
    manageNote:
      'https://www.zohoapis.com/crm/v2/functions/hostportalnewplayernote/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
    searchPlayer:
      'https://www.zohoapis.com/crm/v2/functions/search_players/actions/execute?auth_type=apikey&zapikey=1003.4426a218e3e608ac6b04537a9ed5d997.5437e880ffdb22da4e399290324e7837',
  },
};

export default endpoints;
