import React from 'react';

interface TabContent {
  id: string;
  children: JSX.Element | JSX.Element[];
  active: boolean;
}

const TabContent = ({ id, children, active }: TabContent) => {
  return (
    <div
      className={`tab-pane ${active ? 'show active' : ''}`}
      id={`tabs-${id}`}
      role="tabpanel"
      aria-labelledby={`tabs-${id}-tab`}>
      {children}
    </div>
  );
};

export default TabContent;
