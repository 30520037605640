import React from 'react';

interface ListItem extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
  cols?: string;
  title?: string;
  unique?: boolean;
  justify?: boolean;
  pointer?: boolean;
}
const ListItem = ({
  children,
  cols,
  title,
  unique,
  justify,
  pointer,
  ...others
}: ListItem) => {
  return (
    <div {...others}>
      {title && (
        <div className="flex items-center gap-2  text-sm font-semibold text-gray-600 ">
          <div className="flex-shrink">{title}</div> <hr className="flex-1" />
        </div>
      )}

      {cols ? (
        <div
          className={`grid ${cols} 
                relative 
                items-center 
                pt-2 pb-3 
                ${!unique ? 'border-b border-gray-200 border-dashed' : ''} 
                w-full 
                text-base gap-2 overflow-visible ${
                  pointer
                    ? 'cursor-pointer hover:bg-gray-50 ease-in duration-200'
                    : ''
                }`}>
          {children}
        </div>
      ) : (
        <div
          className={`flex flex-wrap justify-between ${
            justify ? '' : 'lg:justify-start'
          }
                relative items-center pt-2 pb-3 
                ${!unique ? 'border-b border-gray-200 border-dashed' : ''} 
                w-full overflow-visible z-auto text-base ${
                  pointer
                    ? 'cursor-pointer hover:bg-gray-50 ease-in duration-200'
                    : ''
                }`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default ListItem;
