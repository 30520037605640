import React from 'react';
import { render } from 'react-dom';
import './assets/index.css';
import 'tw-elements';
import store from 'state/store';
import { Provider } from 'react-redux';
import createFirebaseApp from 'helpers/firebaseSetup';
import RoutesComponent from 'routes/Routes';

createFirebaseApp();
render(
  <Provider store={store}>
    <RoutesComponent />
  </Provider>,
  document.getElementById('root'),
);
