import React from 'react';
import styles from './TextInput.module.scss';

interface TextArea extends React.HTMLAttributes<HTMLTextAreaElement> {
  children?: React.ReactNode;
  label?: string;
  placeholder: string;
  value?: string;
  floating?: boolean;
  size?: string;
  rounded?: boolean;
  icon?: JSX.Element;
}

const TextArea = ({
  label,
  value,
  floating,
  size,
  icon,
  ...others
}: TextArea) => {
  const id = others.placeholder.split(' ').join('').replace('-', '');
  return (
    <div className="">
      {label && (
        <div className="mb-1 text-left text-sm font-normal">{label}</div>
      )}

      <div className={`${floating ? 'form-floating' : ''} xl:w-full`}>
        <div className="relative flex items-center">
          {icon}

          <textarea
            value={value ? value : ''}
            className={`form-control ${styles.textArea} ${
              size === 'small'
                ? styles.textAreaSm
                : size === 'xsmall'
                ? styles.textAreaXsm
                : styles.textAreaMd
            }`}
            rows={5}
            cols={50}
            {...others}></textarea>
        </div>
        {floating && (
          <label
            htmlFor={id}
            className={`form-label ${
              size === 'small' ? styles.labelInputSm : styles.labelInputMd
            } `}>
            {others.placeholder}
          </label>
        )}
      </div>
    </div>
  );
};

export default TextArea;
