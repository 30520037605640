import { Reducer } from 'redux';
import * as ActionType from '../actions';
import { SEARCH_PLAYER_INITIAL_STATE } from '../../constants/general';
import { SearchPlayerStore } from '../interfaces';
import { ActionTypesSearch } from 'state/action-types/searchPlayer';

const SearchPlayerReducer: Reducer<SearchPlayerStore, ActionTypesSearch> = (
  state = SEARCH_PLAYER_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ActionType.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload.data,
        page: action.payload.page,
        totalItems: action.payload.totalItems,
      };
    case ActionType.TOGGLE_SEARCHING_PLAYER:
      return { ...state, isSearching: action.payload };
    case ActionType.TOGGLE_LOADING_MORE:
      return { ...state, isLoadingMore: action.payload };
    default:
      return state;
  }
};

export default SearchPlayerReducer;
