import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';

function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  onClick: () => void,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick]);
}

interface OutsideAlerterType {
  children: React.ReactNode;
  onClick: () => void;
}

const OutsideAlerter = ({ children, onClick }: OutsideAlerterType) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClick);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;

OutsideAlerter.propTypes = {
  children: propTypes.element,
  onClick: propTypes.func,
};
