import List from 'components/common/List/List';
import ListItem from 'components/common/List/ListItem';
import Spinner from 'components/common/Spinner/Spinner';
import Tabs from 'components/common/Tabs/Tabs';
import React from 'react';
import { PlayerData } from 'state/interfaces';

interface PlayerDetailsSkeleton {
  data: PlayerData;
}

const PlayerDetailsSkeleton = ({ data }: PlayerDetailsSkeleton) => {
  const renderItem = (item: number, key: number) => {
    return (
      <div key={`sk_${key}`} className="border-b select-none py-3">
        <div className="text-sm w-28 h-4 bg-slate-100 mb-3"></div>
        <div className="text-sm w-full h-4 bg-slate-100 mb-1"></div>
        <div className="text-sm w-full h-4 bg-slate-100 mb-1"></div>
        <div className="text-sm w-full h-4 bg-slate-100 mb-1"></div>
      </div>
    );
  };

  return (
    <div className="block lg:flex animate-pulse select-none">
      <div className="flex-grow p-3">
        <List>
          <ListItem unique cols={'grid-cols-1'}>
            <div className="flex justify-between items-center w-full">
              <div className="flex flex-col">
                <p className="text-md font-semibold text-blue-500">
                  {data.First_Name} {data.Name}
                </p>
                <div className="text-sm text-gray-600 ">
                  Player ID: {data.Player_Id}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-700 flex justify-center items-center gap-2">
                  <Spinner type="border" className="!border-2" />
                  Loading details
                </div>
              </div>
            </div>
          </ListItem>
          <ListItem unique cols={'grid-cols-4'} title={'Player Info'}>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Home phone</div>
              <div className="text-sm w-20 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1 '}>
              <div className="text-xs text-gray-500 pb-1">Mobile phone</div>
              <div className="text-sm w-20 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Email</div>
              <div className="text-sm w-20 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1 grid items-center'}>
              <div className="text-xs text-gray-500 pb-1">Pref contact</div>
              <div className="w-full h-5 bg-slate-100 text-gray-500 rounded-sm flex items-center justify-center text-xs">
                Option
              </div>
            </div>
          </ListItem>
          <ListItem unique cols={'grid-cols-4'} title={'Contact flags'}>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Zip code</div>
              <div className="text-sm w-20 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Birthday</div>
              <div className="text-sm w-20 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">
                Associated player ID
              </div>
              <div className="text-sm w-24 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1 grid items-center'}>
              <div className="text-xs text-gray-500 pb-1">S/T</div>
              <div className="w-full h-5 bg-slate-100 text-gray-500 rounded-sm flex items-center justify-center text-xs">
                Option
              </div>
            </div>
          </ListItem>
          <ListItem unique cols={'grid-cols-4'} title={'Reward Info'}>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Membership</div>
              <div className="text-sm w-20 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Current Reward</div>
              <div className="text-sm w-20 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">
                Bounce Back Epx/Type
              </div>
              <div className="text-sm w-24 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1 grid items-center'}>
              <div className="text-xs text-gray-500 pb-1">Reward Up/Down</div>
              <div className="text-sm w-6 h-4 bg-slate-100"></div>
            </div>
          </ListItem>
          <ListItem unique cols={'grid-cols-4'} title={'Objective info'}>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">
                Status change date
              </div>
              <div className="text-sm w-20 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Objective status</div>
              <div className="w-full h-5 bg-slate-100 text-gray-500 rounded-sm flex items-center justify-center text-sm">
                Option
              </div>
            </div>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Vip offer</div>
              <div className="text-sm w-24 h-4 bg-slate-100"></div>
            </div>
            <div className={'col-span-2 lg:col-span-1'}>
              <div className="text-xs text-gray-500 pb-1">Vip Decile</div>
              <div className="text-sm w-24 h-4 bg-slate-100"></div>
            </div>
          </ListItem>
        </List>

        <div className="bg-gray-50 rounded-md">
          <Tabs
            type="tabs"
            align="right"
            selected="gaming-data"
            links={[
              { id: 'gaming-data', title: 'Gaming data' },
              {
                id: 'upcoming-reservations',
                title: 'Upcoming reservations',
              },
              { id: 'object-list', title: 'Objective list' },
            ]}
            contents={[
              {
                content: (
                  <div className="p-2 ">
                    <div className="flex gap-3 mb-1">
                      <div>
                        <div className="text-xs text-gray-500 pb-1">
                          First gaming date
                        </div>
                        <div className="text-sm w-20 h-4 bg-slate-100" />
                      </div>
                      <div>
                        <div className="text-xs text-gray-500 pb-1">
                          Last gaming date
                        </div>
                        <div className="text-sm w-20 h-4 bg-slate-100" />
                      </div>
                      <div>
                        <div className="text-xs text-gray-500 pb-1">
                          Gaming preference
                        </div>
                        <div className="text-sm w-20 h-4 bg-slate-100" />
                      </div>
                    </div>
                    <table className="w-full border text-left">
                      <thead className="bg-gray-200 w-full">
                        <tr className="text-xs ">
                          <th></th>
                          <th>Current</th>
                          <th>L3M</th>
                          <th>L6M</th>
                          <th>L12M</th>
                          <th>L24M</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="!text-xs border">
                          <td className="bg-gray-200 text-center">Trips</td>
                        </tr>
                        <tr className="!text-xs border">
                          <td className="bg-gray-200 text-center">ADT</td>
                        </tr>
                        <tr className="!text-xs border">
                          <td className="bg-gray-200 text-center">Actual</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ),
              },
              {
                content: (
                  <div className="p-2">
                    <table className="w-full border text-left">
                      <thead className="bg-gray-200 w-full">
                        <tr className="text-sm ">
                          <th className="text-center">Cruise Date</th>
                          <th>AM/PM</th>
                          <th>Bus</th>
                          <th>Offer</th>
                          <th>Status</th>
                          <th>Guest Name</th>
                          <th>Guest Phone</th>
                          <th>Guest PlayerID</th>
                          <th>Deposit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-sm border">
                          <td className=" text-center">
                            <div className="text-sm w-14 h-6 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                        </tr>
                        <tr className="text-sm border">
                          <td className=" text-center">
                            <div className="text-sm w-14 h-6 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                          <td>
                            <div className="text-sm w-14 h-4 bg-slate-100" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ),
              },
              {
                content: (
                  <div className="p-2">
                    <table className="w-full border text-left">
                      <thead className="bg-gray-200 w-full">
                        <tr className="text-sm ">
                          <th className="text-center">#</th>
                          <th>Name</th>
                          <th>End date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-sm border">
                          <td className=" text-center">1</td>
                          <td>
                            <div className="w-16 h-4 bg-slate-100 rounded-md flex items-center justify-center" />
                          </td>
                          <td>
                            <div className="w-24 h-4 bg-slate-100 rounded-md flex items-center justify-center" />
                          </td>
                          <td>
                            <div className="w-full h-4 bg-slate-100 text-gray-500 rounded-sm flex items-center justify-center text-xs">
                              Option
                            </div>
                          </td>
                        </tr>
                        <tr className="text-sm border">
                          <td className=" text-center">2</td>
                          <td>
                            <div className="w-16 h-4 bg-slate-100 rounded-md flex items-center justify-center" />
                          </td>
                          <td>
                            <div className="w-24 h-4 bg-slate-100 rounded-md flex items-center justify-center" />
                          </td>
                          <td>
                            <div className="w-full h-4 bg-slate-100 text-gray-500 rounded-sm flex items-center justify-center text-xs">
                              Option
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <div>
        <div className="bg-gray-50 rounded-md h-full w-full lg:w-64 p-3">
          <div className="flex flex-col">
            <p className="text-md font-semibold text-gray-800">Notes</p>
          </div>
          {[...Array(3)].map(renderItem)}
        </div>
      </div>
    </div>
  );
};

export default PlayerDetailsSkeleton;
