import { Reducer } from 'redux';
import * as ActionType from '../actions';
import { ActionTypesDashboard } from '../action-types/dashboard';
import { DASHBOARD_INITIAL_STATE } from '../../constants/general';
import { DashboardStore } from '../interfaces';

const DashboardReducer: Reducer<DashboardStore, ActionTypesDashboard> = (
  state = DASHBOARD_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ActionType.SET_EVENTS_DATA:
      return { ...state, events: action.payload };
    case ActionType.SET_OBJECTIVES_DATA:
      return { ...state, objectives: action.payload };
    case ActionType.TOGGLE_LOADING_DASHBOARD:
      return { ...state, isLoadingDashboard: action.payload };
    case ActionType.TOGGLE_LOADING_OBJECTIVES:
      return { ...state, isLoadingObjectives: action.payload };
    case ActionType.TOGGLE_LOADING_EVENTS:
      return { ...state, isLoadingEvents: action.payload };
    default:
      return state;
  }
};

export default DashboardReducer;
