import React, { useState } from 'react';
import styles from './CodeVerifier.module.scss';
import { VALID_KEYS } from './constants';

interface CodeVerifier {
  length: number;
  getCode?: (code: string) => void;
  submitCode?: () => void;
  isLoading?: boolean;
}

const CodeVerifier = (props: CodeVerifier) => {
  const [code, setCode] = useState([...Array(props.length)]);

  const handleKeyUpCapture = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    try {
      const innterTerxtLength = e.currentTarget.innerText.length;
      // clear div
      const element = document.getElementById(`${e.currentTarget.id}`);
      const index = parseInt(e.currentTarget.id.split('_')[1]);
      const codeArray = code;
      const currentNumber =
        codeArray[index] === undefined ? '' : codeArray[index];
      if (element) {
        // check if key pressed is number
        if (VALID_KEYS.indexOf(e.key) != -1) {
          element.innerText = '';
          // if exists
          if (e.key === 'Backspace') {
            element.innerText = '';
            if (innterTerxtLength === 1) {
              codeArray[index] = undefined;
              setCode(code);
            }
            hancleCheckAllInserted(false);
            handleFocusPrevious(e.currentTarget.id);
          } else if (e.key === 'Enter') {
            codeArray[index] = currentNumber;
            element.innerText = currentNumber;
            if (index === props.length - 1) {
              hancleCheckAllInserted(true);
            } else {
              if (currentNumber) handleFocusNext(e.currentTarget.id);
            }
          } else {
            // add or replace number
            element.innerText = e.key;
            codeArray[index] = e.key;
            setCode(code);
            hancleCheckAllInserted(false);
            handleFocusNext(e.currentTarget.id);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hancleCheckAllInserted = (submit: boolean) => {
    const codeverified = code.filter(digit => isFinite(digit));
    if (codeverified.length === props.length) {
      if (props.getCode) props.getCode(codeverified.join(''));
      if (submit) if (props.submitCode) props.submitCode();
    } else {
      if (props.getCode) props.getCode(codeverified.join(''));
    }
  };

  const handleFocusNext = (id: string) => {
    const nextId = parseInt(id.split('_')[1]) + 1;
    const nextElement = document.getElementById(`cv_${nextId}`);
    nextElement?.focus();
    nextElement?.click();
  };

  const handleFocusPrevious = (id: string) => {
    const nextId = parseInt(id.split('_')[1]) - 1;
    const beforeElement = document.getElementById(`cv_${nextId}`);
    beforeElement?.focus();
    beforeElement?.click();
  };

  const renderItem = (item: number, key: number) => {
    return (
      <div
        id={`cv_${key}`}
        key={`cv_${key}`}
        className={`${
          props.isLoading ? styles.codeElementDisabled : styles.codeElement
        }`}
        contentEditable={!props.isLoading}
        onCopy={() => {
          return false;
        }}
        onCut={() => {
          return false;
        }}
        onPaste={() => {
          return false;
        }}
        onKeyDownCapture={e => handleKeyUpCapture(e)}
      />
    );
  };
  return (
    <div className="flex justify-center gap-2 my-1">
      {[...Array(props.length)].map(renderItem)}
    </div>
  );
};

export default CodeVerifier;
