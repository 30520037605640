import React from 'react';
import styles from './Modal.module.scss';

enum LevelTypes {
  Primary = 1,
  Secondary = 2,
}

interface Modal {
  id: string;
  header?: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
  size?: string;
  opened: boolean;
  level: LevelTypes.Primary | LevelTypes.Secondary;
  toggleModal?: () => void;
}

const Modal = ({
  id,
  header,
  content,
  footer,
  size,
  opened,
  level,
  toggleModal,
}: Modal) => {
  const handleCloseModal = () => {
    if (toggleModal) {
      toggleModal();
    }
  };

  return (
    <>
      <div
        className={`modal fixed top-0 left-0 ${
          opened ? 'show' : 'hidden'
        } w-full h-full outline-none overflow-x-hidden overflow-y-auto ${
          level === 2 ? styles.modalZ : ''
        }`}
        id={id}
        tabIndex={-1}
        aria-labelledby={id + 'Label'}
        aria-hidden="true">
        <div
          className={`modal-dialog ${size} modal-dialog-centered modal-dialog-scrollable relative w-auto `}>
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full bg-white bg-clip-padding rounded-md outline-none text-current">
            {header && (
              <div className="modal-header flex flex-shrink-0 items-center justify-between p-2 border-b border-gray-200 rounded-t-md">
                <h5
                  className="text-lg font-medium leading-normal text-gray-900"
                  id={id + 'Label'}>
                  {header}
                </h5>
                <button
                  type="button"
                  className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}></button>
              </div>
            )}
            <div className="modal-body relative p-2 ">{content}</div>
            {footer && (
              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md gap-1">
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>

      {opened && level === LevelTypes.Primary && (
        <div className={'modal-backdrop fade show'}></div>
      )}
      {opened && level === LevelTypes.Secondary && (
        <div
          className={`modal-backdrop fade show ${styles.backdropOverlay}`}></div>
      )}
    </>
  );
};

export default Modal;
