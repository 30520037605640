import React from 'react';
import style from './LayoutContent.module.scss';

interface LayoutContent {
  head?: JSX.Element | JSX.Element[];
  content?: JSX.Element | JSX.Element[];
}

const LayoutContent = ({
  head: HeadComponent,
  content: ContentComponent,
}: LayoutContent) => {
  return (
    <div className={style.layout}>
      <div className="flex-1">{HeadComponent}</div>
      {/* <div className="flex w-full "> */}
      <>{ContentComponent}</>
      {/* </div> */}
    </div>
  );
};

export default LayoutContent;
