import React from 'react';
import styles from './Button.module.scss';
import { Size } from '../../../constants/general';
import { SizesTypes, StatusTypes } from '../../../interfaces';
import Spinner from '../Spinner/Spinner';
import { BUTTON_TYPES } from './constants';

interface Button extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  status: StatusTypes;
  title?: string;
  outlined?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  size?: SizesTypes;
  label?: string;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  disabled?: boolean;
  spaceLabel?: boolean;
  full?: boolean;
  isDropDown?: boolean;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  loadingText?: string;
  tooltip?: string;
  ripple?: boolean;
}

const Button = ({
  status,
  title,
  children,
  outlined,
  rounded,
  shadow,
  size,
  iconLeft,
  iconRight,
  label,
  disabled,
  spaceLabel,
  full,
  isDropDown,
  loading,
  loadingText,
  tooltip,
  ripple,
  ...others
}: Button) => {
  const handleGetClassName = () => {
    let className = `${styles.btn} ${full ? 'w-full' : ''} ${
      isDropDown ? (loading ? 'justify-center' : '!justify-between') : ''
    } ${disabled ? styles.btnDisabled : ''} ${rounded ? '!rounded-3xl' : ''} ${
      shadow ? 'shadow-md' : ''
    } ${others.className ? others?.className : ''} !leading-normal `;

    if (size === Size.XS) {
      className += styles.btnXsmall;
    } else if (size === Size.SMALL) {
      className += styles.btnSmall;
    } else if (size === Size.LARGE) {
      className += styles.btnLarge;
    } else if (size === Size.MEDIUM) {
      className += styles.btnMedium;
    }

    className += ' ';
    const buttonTypeStyles = BUTTON_TYPES[status];
    className += outlined ? buttonTypeStyles.outlined : buttonTypeStyles.filled;
    return className;
  };

  return (
    <>
      {spaceLabel ? (
        <div className="!h-[20px] block mb-1 text-left font-normal"> </div>
      ) : (
        label && <div className="mb-1 text-left font-normal">{label}</div>
      )}
      {loading ? (
        <button disabled={true} {...others} className={handleGetClassName()}>
          <div className={`${title ? 'mr-2' : ''} `}>
            <Spinner type={'border'} className={'!h-3 !w-3 !border-2'} />
          </div>
          {loadingText ? <div>{loadingText}</div> : <div>Loading...</div>}
        </button>
      ) : (
        <button
          data-mdb-ripple={ripple}
          data-mdb-ripple-color="light"
          title={tooltip}
          disabled={disabled}
          {...others}
          className={handleGetClassName()}>
          {iconLeft && <div className={title ? 'mr-2' : ''}>{iconLeft}</div>}
          {title && <div>{title}</div>}
          {iconRight && <div className="ml-2">{iconRight}</div>}
          {children && <div>{children}</div>}
        </button>
      )}
    </>
  );
};

export default Button;
