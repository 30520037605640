import React, { ComponentType } from 'react';

const withTitle = (Component: ComponentType, title: string) => {
  const WithTitle = ({ ...props }) => {
    document.title = title;
    return <Component {...props} />;
  };
  return WithTitle;
};

export default withTitle;
