import React from 'react';

type SkeletonProps = {
  items: number;
};
const ObjectiveSkeleton = (props: SkeletonProps) => {
  const { items } = props;
  const renderItem = (item: number, key: number) => {
    return (
      <div
        key={`sk_${key}`}
        className={`bg-secondary  px-2 py-3 w-full mx-auto ${
          items === key + 1 ? '' : 'border-b'
        } border-gray-200 border-dashed`}>
        <div className="animate-pulse grid grid-cols-4 gap-2 select-none">
          <div className="col-span-4 sm:col-span-2 lg:col-span-1 min-w-max">
            <p className="font-normal text-sm text-gray-500">Start Date</p>
            <div className=" bg-slate-100 h-4 w-24"></div>
          </div>
          <div className="col-span-4 sm:col-span-2 lg:col-span-1 min-w-max">
            <p className="font-normal text-sm text-gray-500">End Date</p>
            <div className=" bg-slate-100 h-4 w-24"></div>
          </div>
          <div className="col-span-4 sm:col-span-2 lg:col-span-1 min-w-max">
            <p className="font-normal text-sm text-gray-500">Name</p>
            <div className=" bg-slate-100 h-4 w-32"></div>
          </div>
          <div className="col-span-2 sm:col-span-1 lg:col-span-1 flex lg:justify-center items-center">
            <div className="bg-slate-100 rounded-2xl w-28 h-8" />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="grid gap-2 grid-cols-1">
      {[...Array(items)].map(renderItem)}
    </div>
  );
};

export default ObjectiveSkeleton;
