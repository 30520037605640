import { Spinners } from '../../../constants/general';
import { SpinnerTypes } from 'interfaces';
import React from 'react';

interface SpinnerProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  type: SpinnerTypes;
}

const Spinner = ({ type, ...props }: SpinnerProps) => {
  return type === Spinners.BORDER ? (
    <div
      className={`spinner-border animate-spin w-4 h-4 border-3 rounded-full ${props.className}`}
      role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  ) : (
    <div
      className={`spinner-grow animate-spin w-4 h-4 bg-current border-3 rounded-full opacity-0 ${props.className}`}
      role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default Spinner;
