import React from 'react';
import TableCustom from '../../components/common/Table/TableCustom';
import ButtonCustom from '../../components/common/Button/Button';
import { CellValue, Row } from 'react-table';
import {
  capitalizeWords,
  formatPhoneNumber,
  handleFormatDate,
} from 'helpers/common';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';

interface PlayersTable {
  playerList: Array<object>;
  handleTogglePlayerDetailsModal: (
    id_row: number,
    zohoPlayerId?: string,
  ) => void;
  handleAsyncPagination?: () => void;
}

const SearchPlayersTable = ({
  playerList,
  handleTogglePlayerDetailsModal,
  handleAsyncPagination,
}: PlayersTable) => {
  const searchState = useSelector((state: RootState) => state.search);
  const COLUMNS_VALUES = [
    {
      Header: 'id',
      accessor: 'id_status',
      Cell: ({ value }: CellValue) => <span>{value}</span>,
    },
    {
      Header: 'Player ID',
      accessor: 'Player_Id',
      width: 60,
      Cell: ({ value }: CellValue) => <span>{value}</span>,
    },
    {
      Header: 'Player name',
      accessor: 'Full_Name',
      Cell: ({ value }: CellValue) => {
        return <span>{value ? capitalizeWords(value.split(' ')) : '---'}</span>;
      },
    },
    {
      Header: 'Host',
      accessor: 'Host',
      width: 100,
      Cell: ({ value }: CellValue) => {
        return <span>{value ? value : '---'}</span>;
      },
    },
    {
      Header: 'Last contacted',
      accessor: 'Last_Contacted_Date',
      width: 100,
      Cell: ({ value }: CellValue) => {
        return <span>{value ? handleFormatDate(value) : '--/--/----'}</span>;
      },
    },
    {
      Header: 'Mobile Phone',
      accessor: 'Mobile_Phone',
      width: 100,
      Cell: ({ value }: CellValue) => {
        return <span>{value ? formatPhoneNumber(value) : '---'}</span>;
      },
    },
    {
      Header: 'Email',
      accessor: 'Email',
      Cell: ({ value }: CellValue) => {
        return <span className="lowercase">{value ? value : '---'}</span>;
      },
    },
    {
      Header: 'DOB',
      accessor: 'DOB',
      width: 100,
      Cell: ({ value }: CellValue) => {
        return <span>{value ? handleFormatDate(value) : '--/--/----'}</span>;
      },
    },
    // {
    //   Header: 'Zip',
    //   accessor: 'Zip_Code',
    //   width: 60,
    //   Cell: ({ value }: CellValue) => {
    //     return <span>{value ? value : '---'}</span>;
    //   },
    // },
    {
      Header: 'Details',
      accessor: 'id',
      width: 20,
      maxWidth: 20,
      Cell: ({ value, row }: { value: CellValue; row: Row }) => (
        <ButtonCustom
          outlined
          ripple
          title={'View'}
          size="xsmall"
          status="primary"
          onClick={() =>
            handleTogglePlayerDetailsModal(parseInt(row.id), value)
          }
        />
      ),
    },
  ];

  return (
    <TableCustom
      showGlobalSearch
      itemsPerPage={20}
      pageNumber={searchState.page}
      isLoadingMore={searchState.isLoadingMore}
      totalItems={searchState.totalItems}
      showAsyncPagination
      contentTL={<div>{searchState.totalItems} Results</div>}
      contentBL={
        <span className="text-sm text-gray-500">
          Showing {playerList.length} of {searchState.totalItems}{' '}
          {playerList.length === 1 ? 'player' : 'players'}
        </span>
      }
      columns={COLUMNS_VALUES}
      data={playerList}
      hiddenColumns={['id_status']}
      handleAsyncPagination={handleAsyncPagination}
    />
  );
};

export default SearchPlayersTable;
