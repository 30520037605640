import React from 'react';
import { handleGetStatusType, OBJECTIVE_STATUS } from '../../constants/general';
import TableCustom from '../../components/common/Table/TableCustom';
import DropdownContainer from '../../components/common/DropDown/DropDownContainer';
import ButtonCustom from '../../components/common/Button/Button';
import { CellValue, Row } from 'react-table';
import { bindActionCreators } from 'redux';
import { actionCreatorsPlayers } from 'state';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalizeWords,
  formatPhoneNumber,
  handleFormatDate,
} from 'helpers/common';
import { RootState } from 'state/store';
import { ObjectiveInformation } from 'state/interfaces';

interface PlayersTable {
  playerList: Array<object>;
  itemsPerPage: number;
  contentTL?: React.ReactElement;
  objectiveInformation: ObjectiveInformation;
  handleTogglePlayerDetailsModal: (
    id_row: number,
    zohoPlayerId?: string,
  ) => void;
  handleAsyncPagination?: () => void;
}

const PlayersTable = ({
  playerList,
  itemsPerPage,
  contentTL,
  objectiveInformation,
  handleTogglePlayerDetailsModal,
  handleAsyncPagination,
}: PlayersTable) => {
  const playersData = useSelector(
    (state: RootState) => state.players,
  ).playersData;
  const dispatch = useDispatch();
  const { updatePlayerObjectiveStatus, updatePlayerPreferredContact } =
    bindActionCreators(actionCreatorsPlayers, dispatch);

  const COLUMNS_VALUES = [
    {
      Header: 'id',
      accessor: 'id_status',

      Cell: ({ value }: CellValue) => <span>{value}</span>,
    },
    {
      Header: 'Player ID',
      accessor: 'Player_Id',
      width: 60,
      Cell: ({ value }: CellValue) => <span>{value}</span>,
    },
    // {
    //   Header: 'Added',
    //   accessor: 'Created_Time',
    //   width: 80,
    //   Cell: () => (
    //     <span>
    //       {new Date(createdDate).toLocaleDateString('en-US', {
    //         day: '2-digit',
    //         year: 'numeric',
    //         month: '2-digit',
    //       })}
    //     </span>
    //   ),
    // },
    {
      Header: 'Last contacted',
      accessor: 'Last_Contacted_Date',
      minWidth: 100,
      width: 100,
      Cell: ({ value }: CellValue) => {
        return <span>{value ? handleFormatDate(value) : '---'}</span>;
      },
    },
    {
      Header: 'Player name',
      accessor: 'Full_Name',
      Cell: ({ value }: CellValue) => {
        return <span>{value ? capitalizeWords(value.split(' ')) : '---'}</span>;
      },
    },
    {
      Header: 'Home phone',
      accessor: 'Home_Phone',
      minWidth: 100,
      width: 100,
      Cell: ({ value }: CellValue) => {
        return <span>{value ? formatPhoneNumber(value) : '---'}</span>;
      },
    },
    {
      Header: 'Mobile phone',
      accessor: 'Mobile_Phone',
      minWidth: 100,
      width: 100,
      Cell: ({ value }: CellValue) => {
        return <span>{value ? formatPhoneNumber(value) : '---'}</span>;
      },
    },
    {
      Header: 'Email',
      accessor: 'Email',
      Cell: ({ value }: CellValue) => {
        return <span className="lowercase">{value ? value : '---'}</span>;
      },
    },
    {
      Header: 'VIP decile',
      accessor: 'VIP_Decile',
      width: 40,
      Cell: ({ value }: CellValue) => {
        return <span className="lowercase">{value ? value : '---'}</span>;
      },
    },
    {
      Header: 'Zip',
      accessor: 'Zip_Code',
      width: 60,
      Cell: ({ value }: CellValue) => {
        return <span>{value ? value : '---'}</span>;
      },
    },
    {
      Header: 'Pref contact',
      accessor: 'Preferred_Contact_State',
      maxWidth: 100,
      width: 100,
      Cell: ({ value, row }: { value: CellValue; row: Row }) => (
        <DropdownContainer
          full
          outlined
          loading={value.is_loading}
          size="xsmall"
          status={'default'}
          options={['- None -', 'Call', 'Text', 'Email']}
          selected={value.name}
          onChangeSelected={Preferred_contact =>
            updatePlayerPreferredContact(
              Preferred_contact,
              row.values.id_status,
            )
          }
        />
      ),
    },
    {
      Header: 'Objective status',
      accessor: 'Objective_Status',
      maxWidth: 100,
      width: 100,
      Cell: ({ value, row }: { value: CellValue; row: Row }) => (
        <>
          <DropdownContainer
            full
            outlined
            loading={value.is_loading}
            loadingText={'Updating...'}
            size="xsmall"
            status={handleGetStatusType(value.name)}
            setStatusOnChangeSelected={handleGetStatusType}
            onChangeSelected={Objective_status =>
              handleUpdatePlayerObjectiveStatus(
                Objective_status,
                row.values.id_status,
              )
            }
            options={OBJECTIVE_STATUS}
            selected={value.name}
          />
        </>
      ),
    },
    {
      Header: 'Details',
      accessor: 'id',
      width: 20,
      maxWidth: 20,
      Cell: ({ value, row }: { value: CellValue; row: Row }) => (
        <ButtonCustom
          outlined
          ripple
          title={'View'}
          size="xsmall"
          status="primary"
          onClick={() =>
            handleTogglePlayerDetailsModal(parseInt(row.id), value)
          }
        />
      ),
    },
  ];

  const handleUpdatePlayerObjectiveStatus = async (
    Objective_Status: string,
    id: string,
  ) => {
    await updatePlayerObjectiveStatus(Objective_Status, id);
  };

  return (
    <TableCustom
      itemsPerPage={itemsPerPage}
      totalItems={playersData.Count ? playersData.Count : 0}
      pageNumber={playersData.page}
      isLoadingMore={playersData.isLoadingMore}
      showAsyncPagination
      // showGlobalSearch
      contentTL={contentTL}
      handleAsyncPagination={handleAsyncPagination}
      // showPagination
      contentBL={
        <span className="text-sm text-gray-500">
          Showing {playerList.length} results of{' '}
          {playersData.Count ? playersData.Count : playerList.length}
        </span>
      }
      columns={COLUMNS_VALUES}
      data={playerList}
      hiddenColumns={[
        'id_status',
        objectiveInformation.Objective_Type === 'VIP' ? '' : 'VIP_Decile',
      ]}
      emptyText={'No players assigned to you in this objective'}
    />
  );
};

export default PlayersTable;
