import React, { useState } from 'react';
import TabContent from './TabContent';
import TabLink from './TabLink';

type data = { id: string; title: string };
type content = { content: JSX.Element };

interface Tabs {
  contents?: content[];
  links: data[];
  selected: string;
  align?: string;
  type: 'pills' | 'tabs';
  inline?: boolean;
  onChangeItem?: (id: string) => void;
}

const Tabs = ({
  contents,
  links,
  selected,
  align,
  type,
  inline,
  onChangeItem,
}: Tabs) => {
  const [currentSelected, setCurrentSelected] = useState(selected);

  const handleChangeTab = (id: string, title: string) => {
    if (currentSelected !== id) {
      setCurrentSelected(id);
      if (onChangeItem) onChangeItem(title);
    }
  };

  return (
    <>
      <ul
        className={`nav nav-${type} flex flex-col sm:flex-row ${
          inline ? 'flex-normal' : 'flex-wrap'
        } ${align === 'right' ? 'justify-end' : ''} list-none border-b-0 pl-0`}
        id="tabs-tab"
        role="tablist">
        {links.map(link => (
          <TabLink
            type={type}
            key={`tablink-${link.id}`}
            id={link.id}
            title={link.title}
            active={currentSelected === link.id}
            onClick={() => handleChangeTab(link.id, link.title)}
          />
        ))}
      </ul>
      <div className="tab-content" id="tabs-tabContent">
        {contents &&
          links.map((link, key) => (
            <TabContent
              key={`tabcontent-${link.id}`}
              id={link.id}
              active={currentSelected === link.id}>
              <>{contents[key].content}</>
            </TabContent>
          ))}
      </div>
    </>
  );
};

export default Tabs;
