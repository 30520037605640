import { Reducer } from 'redux';
import * as ActionType from '../actions';
import { ActionTypes } from '../action-types';
import { AUTH_INITIAL_STATE } from '../../constants/general';
import { AuthStore } from '../interfaces';

const AuthReducer: Reducer<AuthStore, ActionTypes> = (
  state = AUTH_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ActionType.SET_USER_DATA:
      return { ...state, userData: action.payload };
    case ActionType.SET_LOGIN_DATA:
      return {
        ...state,
        loginInfo: {
          ...state.loginInfo,
          [action.payload.property]: action.payload.value,
        },
      };
    case ActionType.TOGGLE_LOADING_LOGIN:
      return { ...state, isLoadingLogin: action.payload };
    case ActionType.TOGGLE_LOADING_RESET_PASS:
      return { ...state, isLoadingResetPass: action.payload };
    case ActionType.TOGGLE_LOADING_LOGOUT:
      return { ...state, isLoadingLogout: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
