import { ClockIcon, MoonIcon, SunIcon } from '@heroicons/react/outline';
import { SunIcon as NoonIcon } from '@heroicons/react/solid';
import React from 'react';

interface DateLavelProps {
  event: {
    Start_Date: string;
    Name: string;
    Time: string;
    id: string;
  };
}

const enum Time {
  AM = 'AM',
  NOON = 'Noon',
  PM = 'PM',
  BOTH = 'Both',
}

const EventItem = ({ event }: DateLavelProps) => {
  const date = new Date(event.Start_Date.replace(/-/g, '/'));
  const eventDate = date.getDate();
  const currentDate = new Date().getDate();
  const eventMonth = date.getMonth();
  const currentMonth = new Date().getMonth();

  const pastEvent = eventMonth < currentMonth;
  const sameDay = eventDate === currentDate && eventMonth === currentMonth;

  return (
    <div className="overflow-hidden">
      <div className="flex gap-2 w-fit">
        <div
          className={`flex flex-col justify-center items-center ${
            pastEvent
              ? 'bg-red-100 text-red-600'
              : sameDay
              ? 'bg-green-100 text-green-600'
              : 'bg-blue-100 text-blue-500'
          } min-w-[75px] select-none`}>
          <div className="text-2xl leading-tight">{date.getDate()}</div>
          <div className="text-sm leading-none">
            {date.toLocaleDateString('en-US', {
              month: 'long',
            })}
          </div>
        </div>
        <div>
          <p
            className={` ${
              pastEvent
                ? 'text-red-700'
                : sameDay
                ? 'text-green-700'
                : 'text-blue-500'
            } font-semibold mb-1 break-words`}>
            {event.Name}
          </p>
          <div className="bg-gray-50 py-1 px-2 flex gap-2 max-w-max text-sm text-gray-600">
            {event.Time === Time.AM ? (
              <SunIcon className="w-4 h-4" />
            ) : event.Time === Time.NOON ? (
              <NoonIcon className="w-4 h-4" />
            ) : event.Time === Time.PM ? (
              <MoonIcon className="w-4 h-4" />
            ) : (
              <ClockIcon className="w-4 h-4" />
            )}
            {/* {event.Time === Time.AM
              ? '10:00'
              : event.Time === Time.PM
              && '5:00'
            } */}
            {event.Time}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventItem;
