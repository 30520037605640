import React from 'react';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreatorsAuth } from 'state';

const PrivateRouteAuth = () => {
  const dispatch = useDispatch();
  const { setUserData } = bindActionCreators(actionCreatorsAuth, dispatch);
  const isAuth = useAuth();
  setUserData(isAuth);
  return isAuth ? <Navigate to={'/'} replace /> : <Outlet />;
};

export default PrivateRouteAuth;
