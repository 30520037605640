import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LayoutDashboard from '../components/common/Layout/LayoutDashboard';
import Nomatch404 from '../views/404/404';
import LoginView from '../views/Login/LoginView';
import PrivateRoute from './PrivateRoute';
import SearchView from '../views/Search/SearchView';
import MyPerformanceView from '../views/MyPerformance/MyPerformance';
import DashboardView from '../views/Dashboard/DashboardView';
import PlayersObjectiveView from '../views/Objectives/PlayersObjectiveView';
import WithTitle from '../components/HOCs/WithTitle';
import ForgotPassword from '../views/Login/ForgotPassword';
import ProfileView from 'views/Profile/ProfileView';
import PrivateRouteAuth from './PrivateRouteAuth';

const LoginViewCustom = WithTitle(LoginView, 'Login');
const ForgotPasswordCustom = WithTitle(ForgotPassword, 'Forgot Password');
const DashboardViewCustom = WithTitle(DashboardView, 'Dashboard');
const SearchViewCustom = WithTitle(SearchView, 'Search');
const ProfileViewCustom = WithTitle(ProfileView, 'Profile');
const PlayersObjectiveViewCustom = WithTitle(
  PlayersObjectiveView,
  'List of objectives',
);
const MyPerformanceViewCustom = WithTitle(MyPerformanceView, 'My performance');
const NoMatch404Custom = WithTitle(Nomatch404, 'Not found');

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRouteAuth />}>
          <Route path="/auth/login" element={<LoginViewCustom />} />
          <Route
            path="/auth/forgot-password"
            element={<ForgotPasswordCustom />}
          />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/" element={<LayoutDashboard />}>
            <Route index element={<DashboardViewCustom />} />
            <Route path="/search" element={<SearchViewCustom />} />
            <Route path="/search/:playerID" element={<SearchViewCustom />} />
            <Route path="/profile" element={<ProfileViewCustom />} />
            <Route
              path="/objective/:objectiveId"
              element={<PlayersObjectiveViewCustom />}
            />
            <Route
              path="/my-performance"
              element={<MyPerformanceViewCustom />}
            />
          </Route>
        </Route>
        <Route path="/auth" element={<Navigate to="/auth/login" replace />} />
        <Route path="*" element={<NoMatch404Custom />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
