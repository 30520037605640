import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';
import styles from './DropDown.module.scss';

interface DropDownItem {
  title: string;
  selected: string;
  onChangeSelected: (title: string) => void;
}

const DropDownItem = ({ title, selected, onChangeSelected }: DropDownItem) => {
  const handleChangeSelected = (title: string) => {
    onChangeSelected(title);
  };
  return (
    <li onClick={() => handleChangeSelected(title)}>
      <div className={`dropdown-item ${styles.dropdownItem}`}>
        {title}
        {selected === title && (
          <CheckIcon className="ml-3 w-3 h-3 text-green-600" />
        )}
      </div>
    </li>
  );
};

export default DropDownItem;
