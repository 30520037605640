import * as firebase from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

function createFirebaseApp() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  if (!firebase.getApps().length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    // if already initialized, use that one
    firebase.getApp();
  }
}

export default createFirebaseApp;

export const fetchSafe = async (data: object) => {
  const safePostCallWithoutVerifyTokenOnCall = httpsCallable(
    getFunctions(firebase.getApp()),
    'safePostCallWithoutVerifyTokenOnCall',
  );

  return await safePostCallWithoutVerifyTokenOnCall(data);
};
