import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { ArrowUpIcon } from '@heroicons/react/solid';
import styles from './ScrollToTop.module.scss';

interface ScrollToTop {
  notesRef: RefObject<HTMLDivElement>;
}

const ScrollToTop = ({ notesRef }: ScrollToTop) => {
  const [scrollTop, setScrollTop] = useState(false);

  const handleShowScrollTop = useCallback(() => {
    const yPos = notesRef.current?.scrollTop;
    const maxYPos = 300;
    if (yPos)
      if (yPos > maxYPos) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
  }, [notesRef]);

  useEffect(() => {
    notesRef.current?.addEventListener('scroll', handleShowScrollTop);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      notesRef.current?.removeEventListener('scroll', handleShowScrollTop);
    };
  }, [notesRef, handleShowScrollTop]);

  const scrollToTop = () => {
    // console.log('scrolltop');
    notesRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className={`${styles.btnScrollTop} ${scrollTop ? 'fixed' : 'hidden'}
        `}
      onClick={scrollToTop}>
      <ArrowUpIcon className={'h-5 w-5 text-white inline'} />
    </button>
  );
};

export default ScrollToTop;
