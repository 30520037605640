import React from 'react';

type SkeletonProps = {
  items: number;
};

const HEADER_VALUES = [
  'Player ID',
  'Last contacted',
  'Player name',
  'Home phone',
  'Mobile phone',
  'Email',
  'Zip',
  'Pref contact',
  'Objective status',
  'Details',
];

const TableSkeleton = (props: SkeletonProps) => {
  const { items } = props;
  const renderItem = (item: number, key: number) => {
    return (
      <tr key={`sk_${key}`} className="border-b select-none">
        <td className="whitespace-nowrap text-xs font-medium text-gray-900">
          <div className="w-8 h-4 bg-slate-100"></div>
        </td>
        <td className="text-xs text-gray-900 font-light  whitespace-nowrap">
          <div className="w-[70px] h-4 bg-slate-100"></div>
        </td>
        <td className="text-xs text-gray-900 font-light  whitespace-nowrap">
          <div className="w-[70px] h-4 bg-slate-100"></div>
        </td>
        <td className="text-xs text-gray-900 font-light  whitespace-nowrap">
          <div className="w-[70px] h-4 bg-slate-100"></div>
        </td>
        <td className="text-xs text-gray-900 font-light  whitespace-nowrap">
          <div className="w-[70px] h-4 bg-slate-100"></div>
        </td>
        <td className="text-xs text-gray-900 font-light  whitespace-nowrap">
          <div className="w-[70px] h-4 bg-slate-100"></div>
        </td>
        <td className="text-xs text-gray-900 font-lightwhitespace-nowrap">
          <div className="w-12 h-4 bg-slate-100"></div>
        </td>
        <td className="text-xs text-gray-900 font-light  whitespace-nowrap">
          <div className="w-20 h-6 bg-slate-100 text-gray-500 rounded-md flex items-center justify-center">
            Option
          </div>
        </td>
        <td className="text-xs text-gray-900 font-light  whitespace-nowrap">
          <div className="w-20 h-6 bg-slate-100 text-gray-500 rounded-md flex items-center justify-center ">
            Option
          </div>
        </td>
        <td className="text-xs text-gray-900 font-light px-6 py-4 whitespace-nowrap">
          <div className="w-14 h-6 bg-slate-100 text-gray-500 rounded-md flex items-center justify-center">
            View
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div className="grid gap-2 grid-cols-1 overflow-hidden mt-3">
      <div className="animate-pulse">
        <table className="w-full">
          <thead className="text-left">
            <tr>
              {HEADER_VALUES.map(value => (
                <th
                  key={`h-${value}`}
                  scope="col"
                  className="text-sm py-4 text-left">
                  {value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-left">
            {[...Array(items)].map(renderItem)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableSkeleton;
