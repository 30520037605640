import React, { useEffect, useState } from 'react';
import logo from '../../assets/vcclogo.png';

import {
  ChartSquareBarIcon,
  AnnotationIcon,
  PhoneIcon,
  SearchIcon,
  LogoutIcon,
  UserCircleIcon,
  TemplateIcon,
  MenuIcon,
} from '@heroicons/react/outline';
import SideNavItem from './SidNavItem';
import styles from './SideNav.module.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreatorsAuth } from 'state';
import SideNavSection from './SideNavSection';
import { RootState } from 'state/store';

interface SideNavProps {
  outlined?: boolean;
}

const SideNav = (props: SideNavProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const { userLogout } = bindActionCreators(actionCreatorsAuth, dispatch);
  const [isExpanded, setExpanded] = useState(false);

  const handleLogOut = () => {
    userLogout();
  };

  const handleToggleExpanded = () => {
    setExpanded(prevState => !prevState);
    localStorage.setItem('menuState', JSON.stringify(!isExpanded));
  };
  useEffect(() => {
    const menuState = localStorage.getItem('menuState');
    if (menuState) {
      setExpanded(JSON.parse(menuState));
    }
  }, []);

  return (
    <div
      className={`max-h-screen h-screen transition-all ${
        props.outlined ? 'w-80 p-5' : 'w-[300px] pr-5'
      } ${isExpanded ? styles.sideNavCollapsed : ''} `}>
      <div className="sideNav bg-white h-full ">
        <div className="relative pt-2 flex justify-center">
          {!isExpanded && (
            <div className="flex flex-col justify-center items-center">
              <a href="/" className="  ">
                <img
                  src={logo}
                  className="h-16 w-16 object-contain"
                  alt="logo"
                />
              </a>
            </div>
          )}
          <div
            className={`${styles.toggleSideNavButton} toggleSideNavButton`}
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            onClick={handleToggleExpanded}>
            <MenuIcon className="h-6 w-6" />
          </div>
        </div>

        <SideNavSection title="Portal">
          <SideNavItem
            selected={location.pathname === '/'}
            to="/"
            title="Dashboard"
            icon={<TemplateIcon className={styles.sideIcon} />}
          />
          <SideNavItem
            selected={location.pathname === '/search'}
            to="/search"
            title="Search player"
            icon={<SearchIcon className={styles.sideIcon} />}
          />
          <SideNavItem
            selected={location.pathname === '/my-performance'}
            to="/my-performance"
            title="My performance"
            icon={<ChartSquareBarIcon className={styles.sideIcon} />}
          />
        </SideNavSection>
        <SideNavSection title="User">
          <SideNavItem
            selected={location.pathname === '/profile'}
            to="/profile"
            title="Profile"
            icon={<UserCircleIcon className={styles.sideIcon} />}
          />
        </SideNavSection>
        <SideNavSection title="Support">
          <SideNavItem
            external
            to="https://victorycasinocruises.com/"
            title="Call scripts"
            icon={<PhoneIcon className={styles.sideIcon} />}
          />
          <SideNavItem
            external
            to="https://victorycasinocruises.com/"
            title="Helpful tips"
            icon={<AnnotationIcon className={styles.sideIcon} />}
          />
        </SideNavSection>
        <SideNavSection title="Options">
          <SideNavItem
            noredirect
            title={authState.isLoadingLogout ? 'loggint out...' : 'Log out'}
            onClick={() => (authState.isLoadingLogout ? '' : handleLogOut())}
            icon={<LogoutIcon className={styles.sideIcon} />}
          />
        </SideNavSection>
      </div>
    </div>
  );
};

export default SideNav;
